import { useAccount, useMsal } from "@azure/msal-react";
import {
    Drawer,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";
import React, { ChangeEvent, useContext, useState } from "react";
import { useHistory } from "react-router";
import HomeIcon from "@material-ui/icons/Home";
import DownloadIcon from "@material-ui/icons/ArrowDownward";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { UserContext } from "../../context/UserContext";
import { AppContext } from "../../context/AppContext";
import UploadDialog from "./UploadDialog";
interface ApplicationDrawerProps {
    showDrawer: boolean;
    toogleDrawer: () => void;
}

export default function ApplicationDrawer(props: ApplicationDrawerProps) {
    const {
        uploadAtheleteRegistration,
        downloadRegistrationsCSV,
        downloadAthletesCSV,
        getApplicationInfo,
    } = useContext(UserContext);

    const { isAdmin } = useContext(AppContext);

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [showImportDialog, setShowImportDialog] = useState(false);

    const logout = () => instance.logoutPopup();
    const history = useHistory();
    let inputElement: HTMLInputElement | null;

    const handleMenuClick = (whichOne: string) => {
        switch (whichOne) {
            case "home":
                history.push("/");
                getApplicationInfo();
                break;
            case "upload":
                inputElement?.click();
                break;
            case "export":
                downloadRegistrationsCSV();
                break;
            case "export_athletes":
                downloadAthletesCSV();
                break;
            case "logout":
                logout();
                break;
        }
    };

    // eslint-disable-next-line
    const fileSelected = (event: ChangeEvent<HTMLInputElement>): void => {
        event.persist();

        setShowImportDialog(true);

        if (event.target && event.target.files && event.target.files[0])
            uploadAtheleteRegistration(event.target.files[0])?.then(
                (result) => {}
            );
    };

    return (
        <Drawer open={props.showDrawer} onClose={props.toogleDrawer}>
            <div hidden={isAdmin}>
                <ListItem button onClick={() => handleMenuClick("home")}>
                    <ListItemIcon>
                        <HomeIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItem>
            </div>
            <div hidden={isAdmin}>
                <ListItem>
                </ListItem>
            </div>
            <div hidden={isAdmin}>
                <ListItem button onClick={() => handleMenuClick("export")}>
                    <ListItemIcon>
                        <DownloadIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Export registrations" />
                </ListItem>
            </div>
            <div hidden={isAdmin}>
                <ListItem
                    button
                    onClick={() => handleMenuClick("export_athletes")}
                >
                    <ListItemIcon>
                        <DownloadIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Export athletes" />
                </ListItem>
            </div>

            <div hidden={!isAdmin}>
                <ListItem
                    button
                    onClick={() => handleMenuClick("notification")}
                >
                    <ListItemIcon>
                        <NotificationsIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Send notification" />
                </ListItem>
            </div>

            <ListItem button onClick={() => handleMenuClick("logout")}>
                <ListItemIcon>
                    <LogoutIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Logout" secondary={account?.name} />
            </ListItem>

            <UploadDialog
                isOpen={showImportDialog}
                closeImportDialog={setShowImportDialog}
            ></UploadDialog>
        </Drawer>
    );
}

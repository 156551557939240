import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"

const breakpoints = createBreakpoints({})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        banner: {
            width: "100%",
            marginBottom: 60,
            padding: "10px 0",
            boxShadow: "0 1px 2px 0 rgb(63 63 68 / 15%)",
            backgroundColor: "#fff"
        },
        bannerWrapper: {
            maxWidth: 1140,
            margin: "0 auto",
            padding: 10,
            display: "flex",
            justifyContent: "space-between",
            [breakpoints.down("sm")]: {
                flexDirection: "column",
                alignItems: "center",
            }
        },
        logoWrapper: {
            display: "flex",
        },
        logo: {
            height: 50,
            fontFamily: "Century Gothic",
            textDecoration: "none",
            color: "#6f7c87",
            fontSize: "20px",
            display: "flex",
            alignItems: "center"
        },
        logoImg: {
            marginRight: 30
        },
        bannerNav: {
            marginTop: "-6px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-end",
            textAlign: "right",
            alignItems: "flex-start",
            [breakpoints.down("sm")]: {
                marginTop: 0,
                alignItems: "center",
                justifyContent: "center"
            }
        },
        navMsg: {
            width: "100%",
            [breakpoints.down("sm")]: {
                textAlign: "center",
                margin: "15px 0 10px"
            }
        },
        btn: {
            fontFamily: "Century Gothic",
            borderRadius: 2,
            border: "solid 1px #00205b",
            fontSize: 12,
            fontWeight: 700,
            letterSpacing: 1.2,
            textTransform: "uppercase",
            padding: "5px 20px",
            color: "#fff",
            textDecoration: "none",
        },
        loginBtn: {
            backgroundColor: "#00205b",
            marginRight: 5,
            "&:hover": {
                backgroundColor: "#00a9e6",
            },
        },
        signUpBtn: {
            color: "#00205b",
            "&:hover": {
                color: "#00a9e6",
                borderColor: "#00a9e6"
            }
        }
    })
)

function Header() {
    const classes = useStyles()

    return (
        <header className={classes.banner}>
            <div className={classes.bannerWrapper}>
                <div className={classes.logoWrapper}>
                    <a href="/" className={classes.logo}>
                        <img className={classes.logoImg} src="https://s3.eu-central-1.amazonaws.com/euc-cdn.freshdesk.com/data/helpdesk/attachments/production/80004642360/logo/2losujpB78gX90FHr9vfbrhkN18Zu9j1nA.png" alt="Logo" />
                        International Testing Agency
                    </a>
                </div>
            </div>
        </header >
    );
}

export default Header
import { Button, Card, CardActions, CardContent, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import React from 'react'

interface StatCardProps {
    title: string,
    value: string | number,
    handleMore: (vt: string) => void,
    valueType: string
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        title: {
            fontSize: 14,
        },
        yellow: {
            backgroundColor: '#fdffbf',
            boxShadow: theme.shadows[5],
            margin: "0.5rem",
            height: 185
        },
        green: {
            backgroundColor: '#dbffd1',
            boxShadow: theme.shadows[5],
            margin: "0.5rem",
            height: 185
        },
        red: {
            backgroundColor: '#fe6269',
            boxShadow: theme.shadows[5],
            margin: "0.5rem",
            height: 185
        },
        blue: {
            backgroundColor: '#d1ddff',
            boxShadow: theme.shadows[5],
            margin: "0.5rem",
            height: 185
        },
        list: {
            width: 650
        },
        card_content: {
            height: 75
        },
        card_value: {

        }
    })
)


export default function StatCard(props: StatCardProps) {

    const classes = useStyles();

    const getColor = (valueType: string) => {

        switch (valueType) {
            case "all_athletes": {
                return classes.blue;
            }
            case "complete_registration": {
                return classes.green;
            }
            case "incomplete_registration": {
                return classes.yellow;
            }
            case "no_registration": {
                return classes.red;
            }
            default: {
                return classes.blue;
            }
        }
    }

    return (
        <Card className={getColor(props.valueType)}>
            <CardContent>
                <div className={classes.card_content}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {props.title}
                    </Typography>
                </div>
                <div className={classes.card_value}>
                    <Typography variant="h5" component="h3">
                        {props.value}
                    </Typography>
                </div>
            </CardContent>
            <CardActions>
                <Button size="small" onClick={() => { props.handleMore(props.valueType) }}>More</Button>
            </CardActions>
        </Card>
    )
}

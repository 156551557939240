/* tslint:disable */
/* eslint-disable */
/**
 * ITA_Rooming_Scaffold
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Registration,
    RegistrationFromJSON,
    RegistrationToJSON,
} from '../models';

export interface ApiRegistrationsAthleteIdGetRequest {
    id: number;
}

export interface ApiRegistrationsIdDeleteRequest {
    id: number;
}

export interface ApiRegistrationsIdPutRequest {
    id: number;
    registration?: Registration;
}

export interface ApiRegistrationsPostRequest {
    registration?: Registration;
}

/**
 * 
 */
export class RegistrationsApi extends runtime.BaseAPI {

    /**
     */
    async apiRegistrationsAthleteIdGetRaw(requestParameters: ApiRegistrationsAthleteIdGetRequest): Promise<runtime.ApiResponse<Array<Registration>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRegistrationsAthleteIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Registrations/athlete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RegistrationFromJSON));
    }

    /**
     */
    async apiRegistrationsAthleteIdGet(requestParameters: ApiRegistrationsAthleteIdGetRequest): Promise<Array<Registration>> {
        const response = await this.apiRegistrationsAthleteIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiRegistrationsDownloadGetRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Registrations/download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiRegistrationsDownloadGet(): Promise<void> {
        await this.apiRegistrationsDownloadGetRaw();
    }

    /**
     */
    async apiRegistrationsIdDeleteRaw(requestParameters: ApiRegistrationsIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRegistrationsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Registrations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiRegistrationsIdDelete(requestParameters: ApiRegistrationsIdDeleteRequest): Promise<void> {
        await this.apiRegistrationsIdDeleteRaw(requestParameters);
    }

    /**
     */
    async apiRegistrationsIdPutRaw(requestParameters: ApiRegistrationsIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRegistrationsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Registrations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RegistrationToJSON(requestParameters.registration),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiRegistrationsIdPut(requestParameters: ApiRegistrationsIdPutRequest): Promise<void> {
        await this.apiRegistrationsIdPutRaw(requestParameters);
    }

    /**
     */
    async apiRegistrationsPostRaw(requestParameters: ApiRegistrationsPostRequest): Promise<runtime.ApiResponse<Registration>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Registrations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegistrationToJSON(requestParameters.registration),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RegistrationFromJSON(jsonValue));
    }

    /**
     */
    async apiRegistrationsPost(requestParameters: ApiRegistrationsPostRequest): Promise<Registration> {
        const response = await this.apiRegistrationsPostRaw(requestParameters);
        return await response.value();
    }

}

import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Container, CssBaseline, Grid, Paper } from "@material-ui/core";
import { AdminContext, useAdminContext } from "../../context/AdminContext";
import RegistrationsList from "./RegistrationList/RegistrationsList";
import AdminTopBar from "./AdminTopBar/AdminTopBar";

export default function AdminPage() {
    const { isAdmin } = useContext(AppContext);

    const adminContext = useAdminContext();

    return (
        <Paper>
            {isAdmin && (
                <AdminContext.Provider value={adminContext}>
                    <BrowserRouter>
                        <CssBaseline />
                        <Container
                            fixed
                            maxWidth="xl"
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <AdminTopBar></AdminTopBar>
                                </Grid>
                                <Grid item xs={12}>
                                    <Switch>
                                        <Route
                                            path="/"
                                            component={RegistrationsList}
                                        />
                                    </Switch>
                                </Grid>
                            </Grid>
                        </Container>
                    </BrowserRouter>
                </AdminContext.Provider>
            )}
        </Paper>
    );
}

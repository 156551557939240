import Grid from "@material-ui/core/Grid"

import { useMsal } from "@azure/msal-react"

import { loginRequest } from "../../authConfig"
import Header from "./Header"
import Footer from "./Footer"
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import CallCenterIcon from "../../assets/images/call-center-icon.svg"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"

const breakpoints = createBreakpoints({})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formWrapper: {
            backgroundColor: "#fff",
            border: "1px solid #00205b",
            boxShadow: "none",
            borderRadius: 0,
            maxWidth: 1140,
            padding: 40,
            margin: "0 10px",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "space-between",
            "& h2": {
                marginTop: 0,
                marginBottom: 40
            },
            [breakpoints.down("sm")]: {
                flexWrap: "wrap",
                flexDirection: "column"
            }
        },
        loginSection: {
            width: "50%",
            [breakpoints.down("sm")]: {
                width: "100%"
            }
        },
        signUpSection: {
            width: "35%",
            [breakpoints.down("sm")]: {
                width: "100%",
                marginTop: 40
            }
        },
        btn: {
            fontFamily: "Century Gothic",
            borderRadius: 2,
            border: "solid 1px #00205b",
            fontSize: 12,
            fontWeight: 700,
            letterSpacing: 1.2,
            textTransform: "uppercase",
            padding: "5px 20px",
            color: "#fff",
            textDecoration: "none",
        },
        loginBtn: {
            backgroundColor: "#00205b",
            marginRight: 5,
            "&:hover": {
                backgroundColor: "#00a9e6",
            },
        },
        signUpBtn: {
            color: "#00205b",
            "&:hover": {
                color: "#00a9e6",
                borderColor: "#00a9e6"
            },
            [breakpoints.down("sm")]: {
                display: "block",
                textAlign: "center",
            }
        },
        agentLogin: {
            borderTop: "1px solid #ddd",
            margin: "25px 0px 0px",
            padding: "16px 0px 0px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            "& h3": {
                margin: "0 20px 0 0"
            }
        },
        agentIcon: {
            boxShadow: "inset 0px 0px 1px 1px #ddd",
            marginRight: 10,
            width: 42,
            height: 42,
            minWidth: 42,
            backgroundColor: "#fff",
            borderRadius: "50%",
            background: `url(${CallCenterIcon}) center no-repeat`,
            backgroundSize: 17
        },
        greenLoginLink: {
            color: "#02b875",
            textDecoration: "none",
            background: "none",
            border: "none",
            padding: 0,
            fontSize: "16px",
            textTransform: "unset",
            fontWeight: 700,
        }
    })
)

export default function Login() {
    const { instance } = useMsal()

    const loginPopup = () => {
        instance.loginPopup(loginRequest)
    }
    const classes = useStyles()

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            style={{ minHeight: "100vh", width: "100%" }}
        >
            <Header />

            <div className={classes.formWrapper}>
                <section className={classes.loginSection}>
                    <Grid container>
                    <h2>Login to the Rooming App</h2>
                    <button className={`${classes.loginBtn} ${classes.btn}`} onClick={loginPopup}>Login</button>
                    </Grid>
                </section>
            </div>

            <Footer />
        </Grid>
    );
}

/* tslint:disable */
/* eslint-disable */
/**
 * ITA_Rooming_Scaffold
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Building,
    BuildingFromJSON,
    BuildingToJSON,
} from '../models';

export interface ApiBuildingsIdGetRequest {
    id: number;
}

/**
 * 
 */
export class BuildingsApi extends runtime.BaseAPI {

    /**
     */
    async apiBuildingsGetRaw(): Promise<runtime.ApiResponse<Array<Building>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Buildings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BuildingFromJSON));
    }

    /**
     */
    async apiBuildingsGet(): Promise<Array<Building>> {
        const response = await this.apiBuildingsGetRaw();
        return await response.value();
    }

    /**
     */
    async apiBuildingsIdGetRaw(requestParameters: ApiBuildingsIdGetRequest): Promise<runtime.ApiResponse<Building>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiBuildingsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Buildings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BuildingFromJSON(jsonValue));
    }

    /**
     */
    async apiBuildingsIdGet(requestParameters: ApiBuildingsIdGetRequest): Promise<Building> {
        const response = await this.apiBuildingsIdGetRaw(requestParameters);
        return await response.value();
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * ITA_Rooming_Scaffold
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdminSearchResult,
    AdminSearchResultFromJSON,
    AdminSearchResultToJSON,
    ApplicationInfo,
    ApplicationInfoFromJSON,
    ApplicationInfoToJSON,
    CountResult,
    CountResultFromJSON,
    CountResultToJSON,
    Country,
    CountryFromJSON,
    CountryToJSON,
    Registration,
    RegistrationFromJSON,
    RegistrationToJSON,
    User,
    UserFromJSON,
    UserToJSON,
} from '../models';

export interface ApiAdminNotificationPostRequest {
    usersList?: string;
    message?: string;
    countryId?: string;
}

export interface ApiAdminRegistrationsGetRequest {
    pageNumber?: number;
    pageSize?: number;
    pageFilter?: string;
}

export interface ApiAdminRegistrationsIdGetRequest {
    id: number;
}

export interface ApiAdminSearchGetRequest {
    searchTerm?: string;
    pageNumber?: number;
    pageSize?: number;
    pageFilter?: string;
}

export interface ApiAdminStatsGetRequest {
    countryID?: number;
}

export interface ApiAdminUsersGetRequest {
    countryId?: string;
}

/**
 * 
 */
export class AdminApi extends runtime.BaseAPI {

    /**
     */
    async apiAdminCountriesGetRaw(): Promise<runtime.ApiResponse<Array<Country>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/countries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CountryFromJSON));
    }

    /**
     */
    async apiAdminCountriesGet(): Promise<Array<Country>> {
        const response = await this.apiAdminCountriesGetRaw();
        return await response.value();
    }

    /**
     */
    async apiAdminDownloadGetRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAdminDownloadGet(): Promise<void> {
        await this.apiAdminDownloadGetRaw();
    }

    /**
     */
    async apiAdminNotificationPostRaw(requestParameters: ApiAdminNotificationPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.usersList !== undefined) {
            queryParameters['usersList'] = requestParameters.usersList;
        }

        if (requestParameters.message !== undefined) {
            queryParameters['message'] = requestParameters.message;
        }

        if (requestParameters.countryId !== undefined) {
            queryParameters['countryId'] = requestParameters.countryId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAdminNotificationPost(requestParameters: ApiAdminNotificationPostRequest): Promise<void> {
        await this.apiAdminNotificationPostRaw(requestParameters);
    }

    /**
     */
    async apiAdminRegistrationsCountGetRaw(): Promise<runtime.ApiResponse<CountResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/registrations/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CountResultFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminRegistrationsCountGet(): Promise<CountResult> {
        const response = await this.apiAdminRegistrationsCountGetRaw();
        return await response.value();
    }

    /**
     */
    async apiAdminRegistrationsGetRaw(requestParameters: ApiAdminRegistrationsGetRequest): Promise<runtime.ApiResponse<AdminSearchResult>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageFilter !== undefined) {
            queryParameters['pageFilter'] = requestParameters.pageFilter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/registrations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminSearchResultFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminRegistrationsGet(requestParameters: ApiAdminRegistrationsGetRequest): Promise<AdminSearchResult> {
        const response = await this.apiAdminRegistrationsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminRegistrationsIdGetRaw(requestParameters: ApiAdminRegistrationsIdGetRequest): Promise<runtime.ApiResponse<Array<Registration>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAdminRegistrationsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/registrations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RegistrationFromJSON));
    }

    /**
     */
    async apiAdminRegistrationsIdGet(requestParameters: ApiAdminRegistrationsIdGetRequest): Promise<Array<Registration>> {
        const response = await this.apiAdminRegistrationsIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminResetcacheGetRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/resetcache`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAdminResetcacheGet(): Promise<void> {
        await this.apiAdminResetcacheGetRaw();
    }

    /**
     */
    async apiAdminSearchGetRaw(requestParameters: ApiAdminSearchGetRequest): Promise<runtime.ApiResponse<AdminSearchResult>> {
        const queryParameters: any = {};

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageFilter !== undefined) {
            queryParameters['pageFilter'] = requestParameters.pageFilter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminSearchResultFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminSearchGet(requestParameters: ApiAdminSearchGetRequest): Promise<AdminSearchResult> {
        const response = await this.apiAdminSearchGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminStatsGetRaw(requestParameters: ApiAdminStatsGetRequest): Promise<runtime.ApiResponse<ApplicationInfo>> {
        const queryParameters: any = {};

        if (requestParameters.countryID !== undefined) {
            queryParameters['countryID'] = requestParameters.countryID;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/stats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationInfoFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminStatsGet(requestParameters: ApiAdminStatsGetRequest): Promise<ApplicationInfo> {
        const response = await this.apiAdminStatsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminUsersGetRaw(requestParameters: ApiAdminUsersGetRequest): Promise<runtime.ApiResponse<Array<User>>> {
        const queryParameters: any = {};

        if (requestParameters.countryId !== undefined) {
            queryParameters['countryId'] = requestParameters.countryId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     */
    async apiAdminUsersGet(requestParameters: ApiAdminUsersGetRequest): Promise<Array<User>> {
        const response = await this.apiAdminUsersGetRaw(requestParameters);
        return await response.value();
    }

}

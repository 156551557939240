import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
} from "@material-ui/core";
import FaceIcon from "@material-ui/icons/Face";
import React, { useContext } from "react";
import { AdminContext } from "../../../context/AdminContext";

export default function SelectedRegistrations() {
    const { selectedRegistrations } = useContext(AdminContext);

    return (
        <Paper style={{ maxHeight: "200px", overflow: "auto" }}>
            <List>
                {selectedRegistrations &&
                    selectedRegistrations.map((u, index) => {
                        return (
                            <ListItem key={1} role={undefined} dense button>
                                <ListItemIcon>
                                    <FaceIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={u.name!}
                                    secondary={u.country!}
                                />
                            </ListItem>
                        );
                    })}
            </List>
        </Paper>
    );
}

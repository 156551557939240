import { Button, Card, CardActions, CardContent, Container, createStyles, makeStyles, Modal, Theme } from '@material-ui/core';
import React from 'react'

interface DeleteConfirmationDialogProps {
    visible: boolean
    handleClose: () => void
    handleConfirm: () => void
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        root: {
            alignItems: 'center',
            justifyContent: 'center',
            padding: "20px",
                }
    }),
);


export default function DeleteConfirmationDialog(props: DeleteConfirmationDialogProps) {

    const classes = useStyles()

    return (
        <div>
            <Modal
                open={props.visible}
                onClose={props.handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >

                <Container>
                    <Card className={classes.root}>
                    <CardContent>
                        Are you sure you want to delete the registration?
                </CardContent>
                    <CardActions>
                        <Button size="medium" onClick={props.handleClose}>Cancel</Button>
                        <Button color="secondary" size="medium" onClick={props.handleConfirm}>Confirm</Button>
                    </CardActions>

                </Card>
                </Container>
            </Modal>
        </div>
    )
}

/* tslint:disable */
/* eslint-disable */
/**
 * ITA_Rooming_Scaffold
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Building
 */
export interface Building {
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    buildingId?: number;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    buildingLocation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    buildingName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    district?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    additionalComments?: string | null;
}

export function BuildingFromJSON(json: any): Building {
    return BuildingFromJSONTyped(json, false);
}

export function BuildingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Building {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buildingId': !exists(json, 'buildingId') ? undefined : json['buildingId'],
        'buildingLocation': !exists(json, 'buildingLocation') ? undefined : json['buildingLocation'],
        'buildingName': !exists(json, 'buildingName') ? undefined : json['buildingName'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'district': !exists(json, 'district') ? undefined : json['district'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'additionalComments': !exists(json, 'additionalComments') ? undefined : json['additionalComments'],
    };
}

export function BuildingToJSON(value?: Building | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buildingId': value.buildingId,
        'buildingLocation': value.buildingLocation,
        'buildingName': value.buildingName,
        'address': value.address,
        'city': value.city,
        'district': value.district,
        'postalCode': value.postalCode,
        'additionalComments': value.additionalComments,
    };
}



/* tslint:disable */
/* eslint-disable */
/**
 * ITA_Rooming_Scaffold
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CountResult,
    CountResultFromJSON,
    CountResultFromJSONTyped,
    CountResultToJSON,
    RegistrationAdminInfo,
    RegistrationAdminInfoFromJSON,
    RegistrationAdminInfoFromJSONTyped,
    RegistrationAdminInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface AdminSearchResult
 */
export interface AdminSearchResult {
    /**
     * 
     * @type {Array<RegistrationAdminInfo>}
     * @memberof AdminSearchResult
     */
    registrations?: Array<RegistrationAdminInfo> | null;
    /**
     * 
     * @type {CountResult}
     * @memberof AdminSearchResult
     */
    count?: CountResult;
}

export function AdminSearchResultFromJSON(json: any): AdminSearchResult {
    return AdminSearchResultFromJSONTyped(json, false);
}

export function AdminSearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminSearchResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'registrations': !exists(json, 'registrations') ? undefined : (json['registrations'] === null ? null : (json['registrations'] as Array<any>).map(RegistrationAdminInfoFromJSON)),
        'count': !exists(json, 'count') ? undefined : CountResultFromJSON(json['count']),
    };
}

export function AdminSearchResultToJSON(value?: AdminSearchResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registrations': value.registrations === undefined ? undefined : (value.registrations === null ? null : (value.registrations as Array<any>).map(RegistrationAdminInfoToJSON)),
        'count': CountResultToJSON(value.count),
    };
}



import React, { useContext, useEffect, useState } from "react";
import { Building } from "../models";
import { Registration } from "../models/Registration";
import { getBuildingLocationName } from "../utils/Utils";
import { UserContext } from "./UserContext";

type RegistrationContextType = {
    registrationID: number | null | undefined;
    setRegistrationID: (s: number) => void;
    city: string | undefined;
    setCity: (s: string) => void;
    district: string | undefined;
    setDistrict: (s: string) => void;
    address: string | undefined;
    setAddress: (s: string) => void;
    postalCode: string | undefined;
    setPostalCode: (s: string) => void;
    buildingName: string | undefined;
    setBuildingName: (s: string) => void;
    filteredBuildings: Building[] | undefined;
    room: string | undefined;
    setRoom: (s: string) => void;
    comments: string | undefined;
    setComments: (s: string) => void;
    floor: string | undefined;
    setFloor: (s: string) => void;
    athleteId: number[] | undefined;
    setAthleteId: (n: number[]) => void;
    registrationType: number | undefined;
    setRegistrationType: (r: number) => void;
    fromDate: string | undefined;
    setFromDate: (d: string) => void;
    toDate: string | undefined;
    setToDate: (d: string) => void;
    createRegistrationContext: (r: Registration) => void;
    getRegistration: (a: number) => Registration | null;
    saveNewRegistration: (a: number[]) => void;
    updateExistingRegistration: (a: number[], b: number) => void;
    resetFormContext: () => void;
    processedFrom: string;
    processedTo: string;
    setProcessedFrom: (s: string) => void;
    setProcessedTo: (s: string) => void;
};

export const RegistrationContext = React.createContext<RegistrationContextType>(
    {
        registrationID: null,
        setRegistrationID: (n: number) => null,
        city: undefined,
        setCity: (s: string) => null,
        district: undefined,
        setDistrict: (s: string) => null,
        address: undefined,
        setAddress: (s: string) => null,
        postalCode: undefined,
        setPostalCode: (s: string) => null,
        buildingName: undefined,
        setBuildingName: (s: string) => null,
        filteredBuildings: [],
        room: undefined,
        setRoom: (s: string) => null,
        floor: undefined,
        setFloor: (s: string) => null,
        comments: undefined,
        setComments: (s: string) => null,
        athleteId: undefined,
        setAthleteId: (n: number[]) => null,
        registrationType: undefined,
        setRegistrationType: (r: number) => null,
        fromDate: undefined,
        setFromDate: (d: string) => null,
        toDate: undefined,
        setToDate: (d: string) => null,
        createRegistrationContext: (r: Registration) => null,
        getRegistration: (a: number) => null,
        saveNewRegistration: (a: number[]) => null,
        updateExistingRegistration: (a: number[], b: number) => null,
        resetFormContext: () => null,
        processedFrom: "",
        processedTo: "",
        setProcessedFrom: (s: string) => null,
        setProcessedTo: (s: string) => null,
    }
);

RegistrationContext.displayName = "RegistrationContext";

export function useRegistrationContext() {
    const [registrationID, setRegistrationID] = useState<number>();
    const [city, setCity] = useState("");
    const [district, setDistrict] = useState("");
    const [address, setAddress] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [buildingName, setBuildingName] = useState("");
    const [room, setRoom] = useState("");
    const [floor, setFloor] = useState("");
    const [comments, setComments] = useState("");
    const [athleteId, setAthleteId] = useState<number[]>();
    const [registrationType, setRegistrationType] = useState<number>(0);
    const [fromDate, setFromDate] = useState("2021-07-01");
    const [toDate, setToDate] = useState("2021-07-01");
    const [filteredBuildings, setFilteredBuildings] = useState<Building[]>([]);

    const [processedFrom, setProcessedFrom] = useState("2021-07-01");
    const [processedTo, setProcessedTo] = useState("2021-07-01");

    const { buildings, saveRegistration } = useContext(UserContext);

    const createRegistrationContext = (r: Registration) => {
        if (r) {
            setCity(r.locality!);
            setDistrict(r.administrativeArea!);
            setAddress(r.streetAddress!);
            setPostalCode(r.postalCode!);
            setBuildingName(r.buildingName!);
            setRoom(r.roomNumber!);
            setFloor(r.floor ? r.floor : "");
            setComments(r.additionalComments!);
            setAthleteId([r.athleteId!]);
            setRegistrationType(r.registrationType!);
            setFromDate(r.fromDate!);
            setToDate(r.toDate!);
        }
    };

    const getRegistration = (aId: number): Registration => {
        let r: Registration = {
            registrationID: registrationID ? registrationID : undefined,
            locality: city,
            administrativeArea: district,
            streetAddress: address,
            postalCode: postalCode,
            buildingName: buildingName,
            roomNumber: room,
            additionalComments: comments,
            athleteId: aId,
            registrationType: registrationType,
            fromDate: fromDate,
            toDate: toDate,
            isComplete: false,
            floor: floor,
        };

        return r;
    };


    useEffect(() => {
        console.log("Initializing registration context");

        let filtered_buildings = buildings?.filter(
            (b) => b.buildingLocation === getBuildingLocationName(0)
        );
        setFilteredBuildings(filtered_buildings!);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let filtered_buildings = buildings?.filter(
            (b) =>
                b.buildingLocation ===
                getBuildingLocationName(registrationType ?? 1)
        );

        setFilteredBuildings(filtered_buildings!);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registrationType]);

    useEffect(() => {
        if (
            registrationType === 0 ||
            registrationType === 1 ||
            registrationType === 2
        ) {
            let newBuilding = buildings
                ?.filter((b) => b.buildingName === buildingName)
                .pop();
            setDistrict(newBuilding?.district ? newBuilding?.district : "");
            setCity(newBuilding?.city ? newBuilding.city : "");
            setAddress(newBuilding?.address ? newBuilding.address : "");
            setPostalCode(
                newBuilding?.postalCode ? newBuilding.postalCode : ""
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buildingName]);

    const saveNewRegistration = (aId: number[]) => {
        aId.map((v) => {
            saveRegistration(getRegistration(v));
            return null;
        });
    };

    const updateExistingRegistration = (aId: number[], rId: number) => {
        aId.map((v) => {
            let reg = getRegistration(v);
            reg.registrationID = rId;
            saveRegistration(reg);
            return null;
        });
    };

    const resetFormContext = () => {
        console.log("Resetting form context!!!");

        setRegistrationID(undefined);
        setCity("");
        setDistrict("");
        setAddress("");
        setPostalCode("");
        setBuildingName("");
        setRoom("");
        setComments("");
        setAthleteId([]);
        setFloor("");
        setRegistrationType(0);
        setFromDate("2021-07-01");
        setToDate("2021-07-01");
    };

    return {
        registrationID,
        setRegistrationID,
        city,
        setCity,
        district,
        setDistrict,
        address,
        setAddress,
        postalCode,
        setPostalCode,
        buildingName,
        setBuildingName,
        filteredBuildings,
        room,
        setRoom,
        floor,
        setFloor,
        comments,
        setComments,
        athleteId,
        setAthleteId,
        registrationType,
        setRegistrationType,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        createRegistrationContext,
        getRegistration,
        saveNewRegistration,
        updateExistingRegistration,
        resetFormContext,
        processedFrom,
        processedTo,
        setProcessedFrom,
        setProcessedTo,
    };
}

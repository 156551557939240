import {
    Box,
    Container,
    createStyles,
    IconButton,
    makeStyles,
    TextField,
    Theme,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Registration } from "../../models";
import RegistrationCard from "./RegistrationCard";
import AthleteDialog from "./AtheleteDialog/AthleteDialog";
import {
    RegistrationContext,
    useRegistrationContext,
} from "../../context/RegistrationDialogContext";
import { UserContext } from "../../context/UserContext";

interface ParamTypes {
    athleteId: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        details: {
            marginTop: "50px",
        },
        textRed: {
            color: "#ab003c",
        },
        textGreen: {
            color: "#357a38",
        },
        paper: {
            padding: theme.spacing(2),
        },
        title: {
            margin: theme.spacing(4, 0, 0),
        },
        grid: {
            padding: theme.spacing(4),
            marginTop: "10px",
        },
        box: {
            paddingTop: 20,
        },
    })
);

export default function AthleteDetails() {
    const classes = useStyles();
    const history = useHistory();
    const { athleteId } = useParams<ParamTypes>();
    const handleBack = () => history.push("/athletes");
    const { selectedAthlete, setSelectedAthleteId, registrations } =
        useContext(UserContext);

    const [showDialog, setShowDialog] = useState(false);

    // eslint-disable-next-line
    const handleAdd = () => {
        setShowDialog(true);
    };

    const handleDialogClose = () => {
        setShowDialog(false);
    };

    useEffect(() => {
        setSelectedAthleteId(Number.parseInt(athleteId));
        // eslint-disable-next-line
    }, [athleteId]);

    const registrationContext = useRegistrationContext();

    return (
        <div>
            <RegistrationContext.Provider value={registrationContext}>
                <Container className={classes.details} maxWidth="md">
                    <Box padding={"10px"}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <IconButton
                                aria-label="delete"
                                color="primary"
                                onClick={handleBack}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <div style={{ marginLeft: "50px" }}>
                                <Typography variant="h4">
                                    {selectedAthlete?.fullName}
                                </Typography>
                            </div>
                        </div>

                        <br />

                        <TextField
                            label="Accreditation number:"
                            value={selectedAthlete?.accreditationNumber || ""}
                            variant="outlined"
                            fullWidth
                            disabled
                        />

                        <br />
                        <br />

                        <TextField
                            label="Country:"
                            value={selectedAthlete?.country?.countryName || ""}
                            variant="outlined"
                            fullWidth
                            disabled
                        />

                        <br />
                        <br />

                        <TextField
                            label="Sport:"
                            value={selectedAthlete?.discipline?.sportName || ""}
                            variant="outlined"
                            fullWidth
                            disabled
                        />
                    </Box>
                </Container>
                <Container className={classes.grid} maxWidth="md">
                    <Typography gutterBottom variant="h5">
                        Registrations
                    </Typography>
                    {registrations &&
                        registrations.map(
                            (registration: Registration, index: number) => (
                                <RegistrationCard
                                    key={index}
                                    registration={registration}
                                />
                            )
                        )}
                    <Box
                        justifyContent="flex-end"
                        display="flex"
                        className={classes.box}
                    >
                    </Box>
                </Container>

                <AthleteDialog
                    isOpen={showDialog}
                    handleClose={handleDialogClose}
                    atheleteId={[Number.parseInt(athleteId)]}
                    registration={undefined}
                ></AthleteDialog>
            </RegistrationContext.Provider>
        </div>
    );
}

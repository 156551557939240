import { useAccount, useMsal } from "@azure/msal-react"
import {
    Box,
    CircularProgress,
    createStyles,
    Grid,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Theme,
    Typography
} from "@material-ui/core"
import React, { useEffect } from "react"
import { useContext } from "react"
import { useHistory } from "react-router-dom"
import StatCard from "./StatCard/StatCard"
import {pageSize} from '../../config'
import { UserContext } from "../../context/UserContext"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        title: {
            fontSize: 14,
        },
        yellow: {
            backgroundColor: '#fdffbf',
            boxShadow: theme.shadows[5],
            margin: "0.5rem",
            height: 175
        },
        green: {
            backgroundColor: '#dbffd1',
            boxShadow: theme.shadows[5],
            margin: "0.5rem",
            height: 175
        },
        red: {
            backgroundColor: '#fe6269',
            boxShadow: theme.shadows[5],
            margin: "0.5rem",
            height: 175
        },
        blue: {
            backgroundColor: '#d1ddff',
            boxShadow: theme.shadows[5],
            margin: "0.5rem",
            height: 175
        },
        list: {
        }
    })
)

export default function AthleteDetails() {

    const history = useHistory()
    const classes = useStyles()
    const { accounts } = useMsal()
    const account = useAccount(accounts[0] || {})
    const { setAthletesListTitle, applicationInfo, getApplicationInfo, setAthletesPage, loadingInfo } = useContext(UserContext)

    const handleMore = (valueType : string) => {

        switch(valueType){
            case "all_athletes": {
                setAthletesListTitle("All Athletes");
                setAthletesPage({pageFilter: "all_athletes", pageSize: pageSize});
                history.push('/athletes');
                break;
            }
            case "complete_registration": {
                setAthletesListTitle("All athletes with complete registrations");
                setAthletesPage({pageFilter: "complete_registration", pageSize: pageSize});
                history.push('/athletes');
                break;
            }
            case "incomplete_registration": {
                setAthletesListTitle("All athletes with incomplete registrations");
                setAthletesPage({pageFilter: "incomplete_registration", pageSize: pageSize});
                history.push('/athletes');
                break;
            }
            case "no_registration": {
                setAthletesListTitle("All Athletes with no registrations");
                setAthletesPage({pageFilter: "no_registration", pageSize: pageSize});
                history.push('/athletes');
                break;
            }
            default: {
                setAthletesListTitle("All Athletes");
                history.push('/athletes');
                break;
            }
        }

    }

    useEffect(() => {
        if (applicationInfo?.numberOfAthletes === 0)
            getApplicationInfo();
    })

    return (
        <div>
            {loadingInfo
                ?
                <Box paddingTop={15} display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress />
                </Box>
                :
                <Grid container className={classes.root} justify="center" spacing={2}>
                    <Grid item xs={12}>
                        <Box paddingTop={5} display="flex" alignItems="center" justifyContent="center">
                            <Typography gutterBottom variant="h5">
                                Welcome {account?.name} ({applicationInfo?.country})
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <StatCard value={applicationInfo?.numberOfAthletes!} title={"All athletes"} valueType={"all_athletes"} handleMore={(v: string) => { handleMore(v) }}></StatCard>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <StatCard value={applicationInfo?.athletesWithOnlyCompleteRegistrations!} title={"Athletes with complete registrations"} valueType={"complete_registration"} handleMore={(v: string) => { handleMore(v) }}></StatCard>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <StatCard value={applicationInfo?.athletesWithIncompleteRegistrations!} title={"Athletes with incomplete registrations"} valueType={"incomplete_registration"} handleMore={(v: string) => { handleMore(v) }}></StatCard>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <StatCard value={applicationInfo?.athletesWithNoRegistrations!} title={"Athletes with no registrations"} valueType={"no_registration"} handleMore={(v: string) => { handleMore(v) }}></StatCard>
                    </Grid>
                    <Grid item xs={12}>
                        <Box paddingTop={5} paddingLeft={1} display="flex" alignItems="center" >
                            <Typography gutterBottom variant="h5">
                                News feed
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} style={{paddingLeft: 3, paddingRight: 3}}>
                        <List className={classes.list}>
                            <ListItem >
                                <ListItemText primary="Welcome" secondary="Welcome to the ITA rooming application. Starting with July 13th, the application displays the real data based on the long list of athletes in each delegation." />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
        }
        </div>
    )
}

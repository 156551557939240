import { Modal, Container, Card, LinearProgress, List, ListItem, ListItemText, CardContent, CardActions, Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { useContext } from "react";
import { UserContext } from "../../context/UserContext";

interface UploadDialogProps{ 
    isOpen: boolean, 
    closeImportDialog: (b: boolean) => void, 

}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        root: {
            padding: "20px",
        },
    })
);

export default function UploadDialog(props : UploadDialogProps) {

    const classes = useStyles();

    const {
        importErrorMessages,
        importRunning,
    } = useContext(UserContext);
    
    return (
        <React.Fragment>
            <Modal
                open={props.isOpen}
                onClose={() => props.closeImportDialog(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >
                <Container>
                    <Card className={classes.root}>
                        <LinearProgress hidden={!importRunning} />

                        <div hidden={importRunning}>
                            <List dense>
                                {importErrorMessages &&
                                    importErrorMessages.length > 0 &&
                                    importErrorMessages!.map((item, index) => {
                                        return (
                                            <ListItem>
                                                <ListItemText
                                                    primary={
                                                        item.accreditationNumber
                                                    }
                                                    secondary={
                                                        item.errorMessage
                                                    }
                                                ></ListItemText>
                                            </ListItem>
                                        );
                                    })}

                                {importErrorMessages &&
                                    importErrorMessages.length === 0 && (
                                        <ListItem>
                                            <ListItemText
                                                primary={
                                                    "All registrations were imported correctly!"
                                                }
                                            ></ListItemText>
                                        </ListItem>
                                    )}
                            </List>
                        </div>

                        <CardContent></CardContent>
                        <CardActions>
                            <Button
                                color="primary"
                                onClick={() => props.closeImportDialog(false)}
                                size="medium"
                            >
                                Close
                            </Button>
                        </CardActions>
                    </Card>
                </Container>
            </Modal>
        </React.Fragment>
    );
}

/* tslint:disable */
/* eslint-disable */
/**
 * ITA_Rooming_Scaffold
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportErrorMessage
 */
export interface ImportErrorMessage {
    /**
     * 
     * @type {string}
     * @memberof ImportErrorMessage
     */
    accreditationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportErrorMessage
     */
    errorMessage?: string | null;
}

export function ImportErrorMessageFromJSON(json: any): ImportErrorMessage {
    return ImportErrorMessageFromJSONTyped(json, false);
}

export function ImportErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accreditationNumber': !exists(json, 'accreditationNumber') ? undefined : json['accreditationNumber'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
    };
}

export function ImportErrorMessageToJSON(value?: ImportErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accreditationNumber': value.accreditationNumber,
        'errorMessage': value.errorMessage,
    };
}



import { useState } from "react"
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"

const breakpoints = createBreakpoints({})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footer: {
            backgroundColor: "#00205b",
            color: "#fff",
            width: "100%",
            padding: "20px 30px",
            textAlign: "right",
            boxSizing: "border-box",
            position: "absolute",
            bottom: 0,
            [breakpoints.down("sm")]: {
                textAlign: "center",
            }
        },
        link: {
            fontWeight: 700,
            color: "#fff",
            textDecoration: "none"
        },
        hideModal: {
            display: "none"
        },
        showModal: {
            display: "block",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translateX(-50%) translateY(-50%)",
            width: 450,
            boxShadow: "0 2px 6px 0 rgb(0 0 0 / 15%)",
            background: "#fff",
            borderRadius: "0 6px 6px 6px",
            zIndex: 1,
        },
        modalHeader: {
            padding: "10px 40px",
            fontSize: 26,
            fontFamily: "Century Gothic",
            borderBottom: "1px solid #DADFE3"
        },
        modalBody: {
            padding: "16px 40px",
            "& p:first-child": {
                marginTop: 0
            },
            "& a": {
                color: "#00a9e6",
                textDecoration: "none",
                fontWeight: 700
            }
        },
        showBgrd: {
            backgroundColor: "#f8f9fa",
            opacity: 0.8,
            width: "100vw",
            height: "100vh",
            position: "absolute",
        },
        hideBgrd: {
            display: "none"
        },
        closeBtn: {
            position: "absolute",
            width: 20,
            border: 0,
            padding: "8px 8px 10px",
            textAlign: "center",
            left: -36,
            top: 0,
            borderRadius: "5px 0 0 5px",
            color: "#fff",
            fontWeight: 700,
            textShadow: "none",
            backgroundColor: "#183247",
            cursor: "pointer"
        }
    })
)

function Footer() {
    const [displayCookies, setDisplayCookies] = useState(false)
    const classes = useStyles()

    function handleClick() { setDisplayCookies(!displayCookies) }

    return (
        <>
            <footer className={classes.footer}>
                <nav>
                    <a className={classes.link} href="#portal-cookie-info" title="Why we love Cookies" onClick={handleClick}>Cookie policy</a>
                </nav>
            </footer>
            <div className={displayCookies ? classes.showModal : classes.hideModal}>
                <div className={classes.modalHeader}>Why we love Cookies</div>
                <div className={classes.modalBody}>
                    <p>We use cookies to try and give you a better experience in Freshdesk.</p>
                    <p>You can learn more about what kind of cookies we use, why, and how from our <a href="http://freshdesk.com/privacy/" rel="noreferrer" target="_blank">Privacy Policy</a>. If you hate cookies, or are just on a diet, you can disable them altogether too. Just note that the Freshdesk service is pretty big on some cookies (we love the choco-chip ones), and some portions of Freshdesk may not work properly if you disable cookies.</p>
                    <p>We’ll also assume you agree to the way we use cookies and are ok with it as described in our <a rel="noreferrer" href="http://freshdesk.com/privacy/" target="_blank">Privacy Policy</a>, unless you choose to disable them altogether through your browser.</p>
                </div>
                <div className={classes.closeBtn} onClick={handleClick}>X</div>
            </div>
            <div className={displayCookies ? classes.showBgrd : classes.hideBgrd} onClick={handleClick}></div>
        </>
    );
}

export default Footer

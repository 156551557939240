import { Toolbar, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { AdminContext } from "../../../context/AdminContext";

export default function RegistrationsListTableTitle() {

    const {listDescription} = useContext(AdminContext)

    return (
        <Toolbar style={{ justifyContent: "space-between" }}>
            <Typography variant="h6" id="tableTitle" component="div">
                {listDescription}
            </Typography>
        </Toolbar>
    );
}

//Used to transform a registration type into a location amme 
export const getBuildingLocationName = (bType: number) => {

    switch (bType) {
        case 0: {
            return "Olympic Village"
        }
        case 1: {
            return "Olympic Cycling Village"
        }
        case 2: {
            return "Olympic Sailing Village"
        }
    }
};




//Used to transform a registration type into a registration location text
export const getRegistrationType = (rType: number) => {
    switch (rType) {
        case 0: {
            return "Olympic Village";
        }
        case 1: {
            return "Cycling Village"
        }
        case 2: {
            return "Sailing Village"
        }
        case 3: {
            return "Hotel"
        }
        case 4: {
            return "Private"
        }
        default: {
            return "Probably an error"
        }
    }
}

export const parseRegistrationTypeString = (s: string) : string => {

    switch (s) {
        case "OlympicVillage": {
            return "Olympic Village";
        }
        case "CyclingVillage": {
            return "Cycling Village"
        }
        case "SailingVillage": {
            return "Sailing Village"
        }
        default: {
            return s
        }
    }
}

export const getGenderNameShort = (gNumber: number) : string => {
    switch(gNumber) {
        case 0: 
            return "M"
        case 1:  
            return "F"
        case 2: 
            return "O"
        default: 
            return "O"
    }
}
/* tslint:disable */
/* eslint-disable */
/**
 * ITA_Rooming_Scaffold
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegistrationAdminInfo
 */
export interface RegistrationAdminInfo {
    /**
     * 
     * @type {string}
     * @memberof RegistrationAdminInfo
     */
    registrationID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationAdminInfo
     */
    athleteId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationAdminInfo
     */
    accreditationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationAdminInfo
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationAdminInfo
     */
    discipline?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationAdminInfo
     */
    disciplineCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationAdminInfo
     */
    sport?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationAdminInfo
     */
    sportCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationAdminInfo
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationAdminInfo
     */
    registrationType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationAdminInfo
     */
    fromDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationAdminInfo
     */
    toDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationAdminInfo
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationAdminInfo
     */
    gender?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationAdminInfo
     */
    buildingName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationAdminInfo
     */
    room?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationAdminInfo
     */
    floor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationAdminInfo
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationAdminInfo
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationAdminInfo
     */
    comments?: string | null;
}

export function RegistrationAdminInfoFromJSON(json: any): RegistrationAdminInfo {
    return RegistrationAdminInfoFromJSONTyped(json, false);
}

export function RegistrationAdminInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationAdminInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'registrationID': !exists(json, 'registrationID') ? undefined : json['registrationID'],
        'athleteId': !exists(json, 'athleteId') ? undefined : json['athleteId'],
        'accreditationNumber': !exists(json, 'accreditationNumber') ? undefined : json['accreditationNumber'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'discipline': !exists(json, 'discipline') ? undefined : json['discipline'],
        'disciplineCode': !exists(json, 'disciplineCode') ? undefined : json['disciplineCode'],
        'sport': !exists(json, 'sport') ? undefined : json['sport'],
        'sportCode': !exists(json, 'sportCode') ? undefined : json['sportCode'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'registrationType': !exists(json, 'registrationType') ? undefined : json['registrationType'],
        'fromDate': !exists(json, 'fromDate') ? undefined : json['fromDate'],
        'toDate': !exists(json, 'toDate') ? undefined : json['toDate'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'buildingName': !exists(json, 'buildingName') ? undefined : json['buildingName'],
        'room': !exists(json, 'room') ? undefined : json['room'],
        'floor': !exists(json, 'floor') ? undefined : json['floor'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
    };
}

export function RegistrationAdminInfoToJSON(value?: RegistrationAdminInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registrationID': value.registrationID,
        'athleteId': value.athleteId,
        'accreditationNumber': value.accreditationNumber,
        'name': value.name,
        'discipline': value.discipline,
        'disciplineCode': value.disciplineCode,
        'sport': value.sport,
        'sportCode': value.sportCode,
        'country': value.country,
        'registrationType': value.registrationType,
        'fromDate': value.fromDate,
        'toDate': value.toDate,
        'city': value.city,
        'gender': value.gender,
        'buildingName': value.buildingName,
        'room': value.room,
        'floor': value.floor,
        'address': value.address,
        'postalCode': value.postalCode,
        'comments': value.comments,
    };
}



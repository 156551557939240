import { AccountInfo, SilentRequest } from "@azure/msal-browser";
import React, { useContext, useEffect, useState } from "react";
import {
    ApiAthletesGetRequest,
    AthletesApi,
    BuildingsApi,
    RegistrationsApi,
    SearchApi,
    UploadApi,
} from "../apis";
import {
    Athlete,
    Building,
    CountResult,
    ImportErrorMessage,
    Registration,
} from "../models";
import { Configuration } from "../runtime";
import { MsalContext } from "@azure/msal-react";
import { apiScopes, apiAuthority, apiBasePath, pageSize } from "../config";
import { ApplicationInfoApi } from "../apis/ApplicationInfoApi";
import { ApplicationInfo } from "../models/ApplicationInfo";

type UserContextType = {
    selectedAthlete: Athlete | undefined;
    athletes: Athlete[] | undefined;
    buildings: Building[] | undefined;
    athletesCount: number;
    registrations: Registration[] | undefined;
    setAthletesPage: (p: ApiAthletesGetRequest) => void;
    paging: ApiAthletesGetRequest | null;
    setSelectedAthleteId: (id: number) => void;
    setDeleteRegistrationId: (id: number) => void;
    uploadAtheleteRegistration: (f: File) => Promise<void> | null;
    downloadRegistrationsCSV: () => void;
    downloadAthletesCSV: () => void;
    saveRegistration: (r: Registration) => void;
    advancedSearch: (s: string) => void;
    searchString: string | undefined;
    setSearchString: (s: string) => void;
    athletesListTitle: string | null;
    setAthletesListTitle: (s: string) => void;
    applicationInfo: ApplicationInfo | null;
    getApplicationInfo: () => void;
    loadingInfo: boolean;
    setLoadingInfo: (l: boolean) => void;
    loadingAthletes: boolean;
    setLoadingAthletes: (l: boolean) => void;
    importErrorMessages: ImportErrorMessage[] | undefined;
    importRunning: boolean;
};

export const UserContext = React.createContext<UserContextType>({
    selectedAthlete: undefined,
    athletes: [],
    buildings: [],
    athletesCount: 0,
    registrations: [],
    setAthletesPage: (p: ApiAthletesGetRequest) => null,
    paging: null,
    setSelectedAthleteId: (id: number) => null,
    setDeleteRegistrationId: (id: number) => null,
    uploadAtheleteRegistration: (f: File) => null,
    downloadRegistrationsCSV: () => null,
    downloadAthletesCSV: () => null,
    saveRegistration: (r: Registration) => null,
    advancedSearch: (s: string) => null,
    searchString: "",
    setSearchString: (s: string) => null,
    athletesListTitle: "",
    setAthletesListTitle: (s: string) => null,
    applicationInfo: null,
    getApplicationInfo: () => null,
    loadingInfo: false,
    setLoadingInfo: (l: boolean) => null,
    loadingAthletes: false,
    setLoadingAthletes: (l: boolean) => null,
    importErrorMessages: [],
    importRunning: false,
});

UserContext.displayName = "ApplicationContext";

export function useUserContext() {
    const msalContext = useContext(MsalContext);

    const [athletes, setAtheletes] = useState<Athlete[]>();
    const [paging, setPaging] = useState<ApiAthletesGetRequest>({
        pageNumber: 0,
        pageSize: pageSize,
    });
    const [athletesCount, setAthletesCount] = useState(0);
    const [buildings, setBuildings] = useState<Building[]>();
    const [selectedAthlete, setSelectedAthelete] = useState<Athlete>();
    const [registrations, setRegistrations] = useState<Registration[]>();
    const [loadingInfo, setLoadingInfo] = useState(false);
    const [loadingAthletes, setLoadingAthletes] = useState(false);
    const [importErrorMessages, setImportErrorMessage] = useState<
        ImportErrorMessage[]
    >([]);
    const [importRunning, setImportRunning] = useState(false);

    const [applicationInfo, setApplicationInfo] = useState<ApplicationInfo>({
        athletesWithIncompleteRegistrations: 0,
        athletesWithNoRegistrations: 0,
        athletesWithOnlyCompleteRegistrations: 0,
        country: "",
        incompleteRegistrations: 0,
        numberOfAthletes: 0,
    });

    const [searchString, setSearchString] = useState("");
    const [athletesListTitle, setAthletesListTitle] = useState("");

    const setAthletesPage = (p: ApiAthletesGetRequest) => setPaging(p);
    const setSelectedAthleteId = (id: number) => getSelectedAthlete(id);
    const setDeleteRegistrationId = (id: number) => deleteRegistration(id);

    const silentRequest = () => {
        if (msalContext.accounts[0]) {
            const accessTokenRequest: SilentRequest = {
                scopes: apiScopes,
                account: msalContext.accounts[0] as AccountInfo,
                authority: apiAuthority,
            } as SilentRequest;

            return msalContext.instance
                .acquireTokenSilent(accessTokenRequest)
                .then((response) => {
                    const accessToken = response.accessToken;
                    let c: Configuration = new Configuration({
                        accessToken: accessToken,
                        basePath: apiBasePath,
                    });
                    return c;
                });
        }
        return Promise.reject();
    };

    useEffect(() => {
        console.log("Initializing main context");
        getBuildings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        switch (paging.pageFilter) {
            case "all_athletes":
                setAthletesListTitle("All athletes");
                break;
            case "no_registration":
                setAthletesListTitle("Athletes with no registrations");
                break;
            case "incomplete_registration":
                setAthletesListTitle("Athletes with incomplete registrations");
                break;
            case "complete_registration":
                setAthletesListTitle("Athletes with complete registrations");
                break;
            default:
                setAthletesListTitle("All athletes");
                break;
        }
        getAthletesPage();
        getAthletesCount();
        getBuildings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paging]);

    useEffect(() => {
        if (searchString.length > 2) {
            advancedSearch(searchString);
        } else {
            setAthletesPage({
                pageNumber: 0,
                pageSize: pageSize,
                pageFilter: paging.pageFilter
                    ? paging.pageFilter
                    : "all_athletes",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString]);

    const getSelectedAthlete = (id: number) => {
        return silentRequest()
            .then((c) => {
                const api = new AthletesApi(c);
                return api.apiAthletesIdGet({ id: id }).then((athlete) => {
                    setSelectedAthelete(athlete);
                    getRegistrationsForAthlete(id);
                });
            })
            .catch((reason) => {
                console.log(reason);
            });
    };

    const getAthletesPage = () => {
        setLoadingAthletes(true);
        return silentRequest().then((c) => {
            new AthletesApi(c)
                .apiAthletesGet(paging)
                .then((athletes) => setAtheletes(athletes))
                .catch(console.log)
                .then(() => setLoadingAthletes(false))
                .then(() => console.log(loadingAthletes));
        });
    };

    const getAthletesCount = () => {
        return silentRequest().then((c) => {
            new AthletesApi(c)
                .apiAthletesCountGet({
                    filter: paging.pageFilter
                        ? paging.pageFilter
                        : "all_athletes",
                })
                .then((count: CountResult) =>
                    setAthletesCount(count.countResult!)
                )
                .catch(console.log);
        });
    };

    const uploadAtheleteRegistration = (file: File) => {
        setImportRunning(true);
        return silentRequest().then((c) => {
            new UploadApi(c).apiUploadPost({ file: file }).then((result) => {
                setImportErrorMessage(result);
                setImportRunning(false);
                refreshCurrentAthleteList();
            });
        });
    };

    const getRegistrationsForAthlete = (id: number) => {
        return silentRequest().then((c) => {
            new RegistrationsApi(c)
                .apiRegistrationsAthleteIdGet({ id: id })
                .then((registrations) => setRegistrations(registrations));
        });
    };

    const deleteRegistration = (id: number) => {
        return silentRequest().then((c) => {
            new RegistrationsApi(c)
                .apiRegistrationsIdDelete({ id: id })
                .then(() => {
                    getRegistrationsForAthlete(selectedAthlete?.athleteID!);
                    getAthletesPage();
                })
                .catch(console.log);
        });
    };

    const saveRegistration = (r: Registration) => {
        return silentRequest().then((c) => {
            const api = new RegistrationsApi(c);

            if (r.registrationID) {
                return api
                    .apiRegistrationsIdPut({
                        id: r.registrationID,
                        registration: r,
                    })
                    .then((response) => {
                        getRegistrationsForAthlete(r.athleteId!);
                        refreshCurrentAthleteList();
                    });
            } else {
                return api
                    .apiRegistrationsPost({ registration: r })
                    .then((response) => {
                        getRegistrationsForAthlete(r.athleteId!);
                        refreshCurrentAthleteList();
                    });
            }
        });
    };

    const refreshCurrentAthleteList = () => {
        //two scenarios - we come from search or we come from filtered view
        if (
            searchString !== "" &&
            searchString !== null &&
            searchString !== undefined
        ) {
            advancedSearch(searchString);
        } else {
            getAthletesPage();
        }
    };

    const downloadRegistrationsCSV = () => {
        return silentRequest().then((c) => {
            let at = c.accessToken!("mumu", []);

            fetch("/api/Registrations/download", {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + at,
                },
            })
                .then((response) => response.blob())
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `Export.csv`);

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode!.removeChild(link);
                });
        });
    };

    const downloadAthletesCSV = () => {
        return silentRequest().then((c) => {
            let at = c.accessToken!("mumu", []);

            fetch("/api/Athletes/download", {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + at,
                },
            })
                .then((response) => response.blob())
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `Export.csv`);

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode!.removeChild(link);
                });
        });
    };

    const getApplicationInfo = () => {
        setLoadingInfo(true);
        return silentRequest().then((c) => {
            let api = new ApplicationInfoApi(c);

            api.apiApplicationInfoGet().then((result: ApplicationInfo) => {
                setLoadingInfo(false);
                setApplicationInfo(result);
            });
        });
    };

    const advancedSearch = (searchTerm: string) => {
        return silentRequest().then((c) => {
            let api = new SearchApi(c);

            api.apiSearchAdvancedGet({ searchTerm: searchTerm }).then(
                (results) => {
                    setAtheletes(results);
                }
            );
        });
    };

    const getBuildings = () => {
        return silentRequest().then((c) => {
            let api = new BuildingsApi(c);

            api.apiBuildingsGet().then((results) => {
                setBuildings(results);
            });
        });
    };

    return {
        selectedAthlete,
        athletes,
        buildings,
        athletesCount,
        registrations,
        setAthletesPage,
        paging,
        setSelectedAthleteId,
        setDeleteRegistrationId,
        uploadAtheleteRegistration,
        downloadRegistrationsCSV,
        downloadAthletesCSV,
        saveRegistration,
        advancedSearch,
        searchString,
        setSearchString,
        athletesListTitle,
        setAthletesListTitle,
        applicationInfo,
        getApplicationInfo,
        loadingInfo,
        setLoadingInfo,
        loadingAthletes,
        setLoadingAthletes,
        importErrorMessages,
        importRunning
    };
}

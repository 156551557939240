import {
    Box,
    Checkbox,
    IconButton,
    Popover,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from "@material-ui/core";
import React, { useContext, useRef } from "react";
import moment from "moment";
import { RegistrationAdminInfo } from "../../../models";

import FileCopyIcon from "@material-ui/icons/FileCopy";
import DescriptionIcon from "@material-ui/icons/Description";

import { AdminContext } from "../../../context/AdminContext";

interface RegistrationsListTableRowProps {
    row: RegistrationAdminInfo;
    index: number;
}

export default function RegistrationsListTableRow(
    props: RegistrationsListTableRowProps
) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );

    const {
        exportRegistrationsForAthlete,
        selectedRegistrations,
        setSelectedRegistrations,
        setSelectedCountry,
        countries,
    } = useContext(AdminContext);

    const textAreaRef = useRef<any>(null);

    const open = Boolean(anchorEl);

    const id = open ? "simple-popover" : undefined;

    const startRegistrationExport = () => {
        exportRegistrationsForAthlete(props.row);
    };

    function copyToClipboard(event: React.MouseEvent<HTMLButtonElement>) {
        function listener(e: any) {
            let str = textAreaRef.current.innerHTML;
            e.clipboardData.setData("text/html", str);
            e.clipboardData.setData("text/plain", str);
            e.preventDefault();
        }
        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);

        setAnchorEl(event.currentTarget);

        const delayDebounceFn = setTimeout(() => {
            setAnchorEl(null);
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }

    const handleRegistrationSelected = (
        registration: RegistrationAdminInfo,
        checked: boolean
    ) => {
        if (checked) {
            if (selectedRegistrations!.length > 0) {
                if (
                    registration.country === selectedRegistrations![0].country
                ) {
                    let newRegistrations = [
                        ...selectedRegistrations!,
                        registration,
                    ];
                    setSelectedRegistrations(newRegistrations);
                }
            } else {
                let newRegistrations = [
                    ...selectedRegistrations!,
                    registration,
                ];
                setSelectedRegistrations(newRegistrations);
                let country = countries.filter(
                    (c) => c.countryName === registration.country
                )[0];
                setSelectedCountry(country);
            }
        } else {
            let filteredSelection = selectedRegistrations!.filter(
                (b) => b.athleteId !== registration.athleteId
            );
            setSelectedRegistrations(filteredSelection);
        }
    };

    return (
        <React.Fragment>
            <TableRow key={props.index} hover>
                <TableCell padding="checkbox">
                    <Checkbox
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                            checked: boolean
                        ) => {
                            handleRegistrationSelected(props.row, checked);
                        }}
                        checked={selectedRegistrations?.includes(props.row)}
                    />
                </TableCell>
                <TableCell align="center">
                    <b>{props.row.name}</b>
                </TableCell>
                <TableCell align="center">
                    <div style={{ display: "flex" }}>
                        <Tooltip
                            title="Copy the details to clipboard"
                            aria-label="add"
                        >
                            <IconButton
                                onClick={copyToClipboard}
                                size="small"
                                color="primary"
                                aria-label="add to shopping cart"
                            >
                                <FileCopyIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Generate PDF document" aria-label="add">
                            <IconButton
                                onClick={() => {
                                    startRegistrationExport();
                                }}
                                size="small"
                                color="primary"
                                aria-label="add to shopping cart"
                            >
                                <DescriptionIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </TableCell>
                <TableCell align="center">
                    <b>{props.row.gender}</b>
                </TableCell>
                <TableCell align="center">{props.row.country}</TableCell>
                <TableCell align="center">
                    {props.row.accreditationNumber}
                </TableCell>
                <TableCell align="center">{props.row.sport}</TableCell>
                <TableCell align="center">{props.row.discipline}</TableCell>
                <TableCell align="center">
                    {props.row.registrationType}
                </TableCell>
                <TableCell align="center">
                    {props.row.fromDate
                        ? moment(props.row.fromDate).format("MM/DD/yyyy")
                        : ""}
                </TableCell>
                <TableCell align="center">
                    {props.row.toDate
                        ? moment(props.row.toDate).format("MM/DD/yyyy")
                        : ""}
                </TableCell>
                <TableCell align="center">{props.row.city}</TableCell>
                <TableCell align="center">{props.row.address}</TableCell>
                <TableCell align="center">{props.row.buildingName}</TableCell>
                <TableCell align="center">{props.row.floor}</TableCell>
                <TableCell align="center">{props.row.room}</TableCell>
                <TableCell align="center">{props.row.postalCode}</TableCell>
                <TableCell align="center">
                <Tooltip title={props.row.comments ?? ""} aria-label="add">
                    <Box component="div" overflow="hidden" style={{maxHeight:"40px"}} textOverflow="ellipsis">
                        {props.row.comments}
                    </Box>
                    </Tooltip>
                </TableCell>
            </TableRow>

            <div hidden ref={textAreaRef}>
                <p style={{ fontSize: "20px" }}>{props.row.name}</p>
                <p>Accreditation number: {props.row.accreditationNumber}</p>
                <p>Country: {props.row.country}</p>
                <p>Gender: {props.row.gender}</p>
                <p>Discipline: {props.row.discipline}</p>
                <p>Registration type: {props.row.registrationType}</p>
                <p>
                    From date:{" "}
                    {props.row.fromDate
                        ? moment(props.row.fromDate).format("MM/DD/yyyy")
                        : ""}
                </p>
                <p>
                    To date:{" "}
                    {props.row.toDate
                        ? moment(props.row.toDate).format("MM/DD/yyyy")
                        : ""}
                </p>
                <p>City: {props.row.city}</p>
                <p>Address: {props.row.address}</p>
                <p>Postal code: {props.row.postalCode}</p>
                <p>Building name: {props.row.buildingName}</p>
                <p>Floor: {props.row.floor}</p>
                <p>Room number: {props.row.room}</p>
                <p>Comments: {props.row.comments}</p>
            </div>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Typography style={{ padding: "10px" }}>
                    Content was copied to clipboard.
                </Typography>
            </Popover>
        </React.Fragment>
    );
}

/* tslint:disable */
/* eslint-disable */
/**
 * ITA_Rooming_Scaffold
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Country,
    CountryFromJSON,
    CountryFromJSONTyped,
    CountryToJSON,
} from './';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    _function?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    emailExternal?: string | null;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    countryId?: number;
    /**
     * 
     * @type {Country}
     * @memberof User
     */
    country?: Country;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        '_function': !exists(json, 'function') ? undefined : json['function'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'emailExternal': !exists(json, 'emailExternal') ? undefined : json['emailExternal'],
        'countryId': !exists(json, 'countryId') ? undefined : json['countryId'],
        'country': !exists(json, 'country') ? undefined : CountryFromJSON(json['country']),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'title': value.title,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'function': value._function,
        'email': value.email,
        'emailExternal': value.emailExternal,
        'countryId': value.countryId,
        'country': CountryToJSON(value.country),
    };
}



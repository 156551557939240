/* tslint:disable */
/* eslint-disable */
/**
 * ITA_Rooming_Scaffold
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * 
     * @type {number}
     * @memberof Country
     */
    countryID?: number;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    countryName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    countryCode?: string | null;
}

export function CountryFromJSON(json: any): Country {
    return CountryFromJSONTyped(json, false);
}

export function CountryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Country {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryID': !exists(json, 'countryID') ? undefined : json['countryID'],
        'countryName': !exists(json, 'countryName') ? undefined : json['countryName'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
    };
}

export function CountryToJSON(value?: Country | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countryID': value.countryID,
        'countryName': value.countryName,
        'countryCode': value.countryCode,
    };
}



import React, { useContext, useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"
import Pagination from '@material-ui/lab/Pagination'
import Paper from "@material-ui/core/Paper"
import Box from '@material-ui/core/Box'
import { Athlete } from "../../models"
import { pageSize } from "../../config"
import { useHistory } from "react-router-dom"
import { Button, Checkbox, Container, Fade, Tooltip, Typography } from "@material-ui/core"
import AthleteDialog from "../AthleteDetails/AtheleteDialog/AthleteDialog"
import WarningIcon from '@material-ui/icons/Warning';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { red, yellow, green } from "@material-ui/core/colors"
import RowPlaceholder from "./RowPlaceholder"
import { RegistrationContext, useRegistrationContext } from "../../context/RegistrationDialogContext"
import AthleteListTableHead from "./AthleteListTableHead"
import AthleteListTableTitle from "./AthleteListTableTitle"
import { getGenderNameShort } from "../../utils/Utils"
import { UserContext } from "../../context/UserContext"

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    bulkActionDialog: {
        padding: "15px",
        marginBottom: "10px"
    }
}))

export default function AthleteList() {
    const classes = useStyles()
    const history = useHistory()
    const { athletes, athletesCount, setAthletesPage, searchString, setSearchString, athletesListTitle, paging, loadingAthletes } = useContext(UserContext)

    const [selectedAthletes, setSelectedAthletes] = useState<Athlete[]>([])
    const [showBulk, setShowBulk] = useState<boolean>(false)

    const registrationContext = useRegistrationContext();

    const handleToggleBulk = () => {
        setShowBulk(!showBulk); 
    }

    const changePage = (page: number) => {
        setAthletesPage({ pageNumber: page - 1, pageSize: pageSize, pageFilter: paging?.pageFilter ? paging.pageFilter : "all_athletes" })
    }

    const checkBoxChanged = (a: Athlete, c: boolean) => {
        if (c) {
            setSelectedAthletes(selectedAthletes => [...selectedAthletes!, a])
        } else {
            let filteredSelection = selectedAthletes.filter(b => b.athleteID !== a.athleteID);
            setSelectedAthletes(filteredSelection)
        }
    }

    useEffect(() => {

        //this will handle the situation where we refresh the page directly

        if (athletes?.length === 0 || athletes === undefined || athletes === null) {
            setAthletesPage({ pageNumber: 0, pageSize: pageSize, pageFilter: paging?.pageFilter ? paging.pageFilter : "all_athletes" })
        }
        // eslint-disable-next-line
    }, [])

    const checkAllChanged = (val: boolean) => {
        setSelectedAthletes(val ? athletes! : [])
    }

    let isAllSelected = athletes !== undefined && selectedAthletes.length > 0 && selectedAthletes.length === athletes.length
    const resetSearch = () => {
        setSearchString('')
    }

    const calcPaging = (): number => {
        return Math.floor((athletesCount / pageSize)) + 1;
    }

    const changeFiltering = (filter: string) => {
        switch(filter){
            case "All athletes":
                setAthletesPage({pageSize: pageSize, pageNumber: 0, pageFilter: "all_athletes" });
                break;
            case "No registrations":
                setAthletesPage({pageSize: pageSize, pageNumber: 0, pageFilter: "no_registration" });
                break;
            case "Incomplete registrations":
                setAthletesPage({pageSize: pageSize, pageNumber: 0, pageFilter: "incomplete_registration" });
                break;
            case "Complete registrations":
                setAthletesPage({pageSize: pageSize, pageNumber: 0, pageFilter: "complete_registration" });
                break;
            default:
                setAthletesPage({pageSize: pageSize, pageNumber: 0, pageFilter: "all_athletes" });
                break;
        }
    }

    const listTitle = searchString ? 'Filtered results' : athletesListTitle
    const statusIcon = (row: Athlete) => {
        if (row.registrations === undefined || row.registrations?.length === 0) {
            return (
                <Tooltip title="No registrations">
                    <WarningIcon fontSize="small" color="disabled" style={{ color: red[500] }}></WarningIcon>
                </Tooltip>
            )
        } else if (row.registrations !== undefined && (row.registrations?.length !== 0 && row.registrations?.filter(r => r.isComplete === false).length !== 0)) {
            return (
                <Tooltip title="Incomplete registrations">
                    <WarningIcon fontSize="small" color="disabled" style={{ color: yellow[500] }}></WarningIcon>
                </Tooltip>
            )
        } else if (row.registrations !== undefined && (row.registrations?.length !== 0 && row.registrations?.filter(r => r.isComplete !== false).length !== 0)) {
            return (
                <Tooltip title="Complete registrations">
                    <DoneOutlineIcon fontSize="small" color="disabled" style={{ color: green[500] }}></DoneOutlineIcon>
                </Tooltip>
            )
        }
    }

    return (
        <RegistrationContext.Provider value={registrationContext}>
            <Container maxWidth="md" style={{ marginTop: "2rem", paddingRight: "0px", paddingLeft: "0px" }}>
                <div hidden={searchString ? searchString.length < 3 : true}>
                    <Fade in={true}>
                        <Paper elevation={3} className={classes.bulkActionDialog}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Typography variant="body1">
                                    Athletes are currently filtered by: {searchString}
                                </Typography>
                                <Button variant="contained" color="primary" onClick={resetSearch}>Clear filters</Button>
                            </div>
                        </Paper>
                    </Fade>
                </div>

                <div hidden={selectedAthletes.length === 0}>
                    <Fade in={selectedAthletes.length > 0}>
                        <Paper elevation={3} className={classes.bulkActionDialog}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Typography variant="body1">
                                    {selectedAthletes.length} selected.
                            </Typography>
                                <Button variant="contained" color="primary" onClick={handleToggleBulk}>Bulk register</Button>
                            </div>
                        </Paper>
                    </Fade>
                </div>

                <Paper>
                    <AthleteListTableTitle title={listTitle!} filteringHandler={changeFiltering}></AthleteListTableTitle>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <AthleteListTableHead isAllSelected={isAllSelected} handleCheckClick={checkAllChanged}></AthleteListTableHead>
                            <TableBody>
                                {athletes && !loadingAthletes &&
                                    athletes.map((row: Athlete) => (
                                        <TableRow key={row.athleteID} hover >
                                            <TableCell padding="checkbox">
                                                <Checkbox onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => checkBoxChanged(row, checked)} checked={selectedAthletes.filter(a => (a.athleteID === row.athleteID)).length > 0} />
                                            </TableCell>
                                            <TableCell width={"150rem"} component="th" scope="row" onClick={() => history.push('athlete/' + row.athleteID)} style={{ cursor: 'pointer' }}>
                                                <b>{row.fullName}</b>
                                            </TableCell>
                                            <TableCell align="center" width="20px">
                                                <div style={{ display: "flex" }}>
                                                    {statusIcon(row)}
                                                </div>
                                            </TableCell>
                                            <TableCell align="center">
                                                {getGenderNameShort(row.gender!)}
                                            </TableCell>
                                            <TableCell align="center">
                                                {row.discipline!.sportName}
                                            </TableCell>
                                            <TableCell align="center">
                                                {row.accreditationNumber}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                        {loadingAthletes &&
                            <RowPlaceholder></RowPlaceholder>
                        }
                    </TableContainer>
                </Paper>
                <Box my={2} display="flex" justifyContent="center">
                    <Pagination hidden={searchString?.length! > 2} count={calcPaging()} shape="rounded" color="primary" onChange={(ev, page) => changePage(page)} />
                </Box>
                <AthleteDialog handleClose={handleToggleBulk} isOpen={showBulk} atheleteId={selectedAthletes.map(a => a.athleteID!)} registration={undefined}></AthleteDialog>
            </Container>
        </RegistrationContext.Provider>
    )
}

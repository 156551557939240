import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import FaceIcon from "@material-ui/icons/Face";
import React, { useContext } from "react";
import { AdminContext } from "../../../context/AdminContext";

export default function UsersList() {
    const { selectedCountryUsers } = useContext(AdminContext);

    return (
        <List>
            {selectedCountryUsers &&
                selectedCountryUsers.map((u, index) => {
                    return (
                        <ListItem key={1} role={undefined} dense button>
                            <ListItemIcon>
                                <FaceIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={(u.firstName ? u.firstName : "") + " " + (u.lastName ? u.lastName :"")}
                                secondary={u.email}
                            />
                        </ListItem>
                    );
                })}
        </List>
    );
}

import { Container, CssBaseline, Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import TopBar from "../TopBar/TopBar";
import AthleteDetails from "../AthleteDetails/AthleteDetails";
import AthleteList from "../AthleteList/AthleteList";
import Home from "../Home/Home";
import { AppContext } from "../../context/AppContext";
import { UserContext, useUserContext } from "../../context/UserContext";

export default function UserPage() {
  const { isAdmin } = useContext(AppContext);

  const userContext = useUserContext();

  return (
    <div>
      {!isAdmin && (
        <UserContext.Provider value={userContext}>
        <BrowserRouter>
          <CssBaseline />
          <Container
            fixed
            maxWidth="md"
            style={{ paddingLeft: 0, paddingRight: 0, height: "100%" }}
          >
            <Grid container>
              <Grid item xs={12}>
                <TopBar></TopBar>
              </Grid>
              <Grid item xs={12}>
                <Switch>
                  <Route
                    path="/athlete/:athleteId"
                    component={AthleteDetails}
                  />
                  <Route path="/athletes" component={AthleteList} />
                  <Route path="/" component={Home} />
                </Switch>
              </Grid>
            </Grid>
          </Container>
        </BrowserRouter>
        </UserContext.Provider>
      )}
    </div>
  );
}

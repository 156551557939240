import React, { useEffect } from "react";

import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";

import Login from "./controls/Login/Login";
import { AppContext, useAppContext } from "./context/AppContext";

import { useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";

import UserPage from "./controls/UserPage/UserPage";
import AdminPage from "./controls/AdminPage/AdminPage";

function App() {
    const appContext = useAppContext();
    const { instance } = useMsal();

    //this will check the user claims and set the isAdmin property in the context
    //the problem is that we rebuild this component on refresh but we don't reauthenticate
    //the solution would be to add the isadmin to localstorage too and prepopulate the context from there

    useEffect(() => {

        console.log("Loading component...");

        // This will be run on component mount
        const callbackId = instance.addEventCallback((message: any) => {
            // This will be run every time an event is emitted after registering this callback
            if (message.eventType === EventType.LOGIN_SUCCESS) {
                const result = message.payload;
                try {
                    if (
                        result.account.idTokenClaims.roles.filter(
                            (r: any) => r === "Read.AllAthletes"
                        ).length > 0
                    ) {
                        appContext.setIsAdmin(true);
                        sessionStorage.setItem("isAdmin", "true");
                    } else {
                        appContext.setIsAdmin(false);
                        sessionStorage.setItem("isAdmin", "false");
                    }
                } catch (exception) {}
            }
            if (message.eventType === EventType.LOGOUT_SUCCESS) {
                try {
                    appContext.setIsAdmin(false); //we probably should also do more state reset here
                } catch (exception) {}
            }
        });
        return () => {
            // This will be run on component unmount
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div className="App">
            <AuthenticatedTemplate>
                <AppContext.Provider value={appContext}>
                    <UserPage />
                    <AdminPage />
                </AppContext.Provider>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Login></Login>
            </UnauthenticatedTemplate>
        </div>
    );
}

export default App;

import { TableCell, TableRow } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'

export default function RowPlaceholder() {

    const n : number = 10;

    return (
        <div>{
            [...Array(n)].map((e, i) => <div className="busterCards" key={i}>
                <TableRow>
                    <TableCell padding="checkbox">
                    </TableCell>
                    <TableCell width={"200rem"} component="th" scope="row">
                        <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell align="center" width="100rem">
                        <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell width={"200rem"} align="center">
                        <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell width={"150rem"} align="center">
                        <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell width={"200rem"} align="center">
                        <Skeleton animation="wave" />
                    </TableCell>
                </TableRow>
            </div>)
        }
        </div>
    )
}

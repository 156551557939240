import React, { ReactNode, useContext, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from "@material-ui/core";

import {
    Athlete,
    Building,
    Registration,
    RegistrationType,
} from "../../../models";
import Select from "@material-ui/core/Select";
import moment from "moment";
import { RegistrationContext } from "../../../context/RegistrationDialogContext";

import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { UserContext } from "../../../context/UserContext";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        labelRoot: {
            fontSize: 12,
        },
        labelFocused: {},
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        root: {
            padding: "20px",
        },
    })
);

interface AthleteDialogProps {
    handleClose: () => void;
    isOpen: boolean;
    atheleteId: number[];
    registration: Registration | undefined;
    athletes?: Athlete[];
}

export default function AthleteDialog(props: AthleteDialogProps) {
    const classes = useStyles();

    const [openStatus, setOpenStatus] = useState(false);

    const { buildings } = useContext(UserContext);

    const {
        city,
        setCity,
        address,
        setAddress,
        postalCode,
        setPostalCode,
        buildingName,
        setBuildingName,
        room,
        setRoom,
        floor,
        setFloor,
        comments,
        setComments,
        registrationType,
        setRegistrationType,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        filteredBuildings,
        saveNewRegistration,
        updateExistingRegistration,
        resetFormContext,
    } = useContext(RegistrationContext);

    const handleClose = () => {
        props.handleClose();
        resetFormContext();
    };

    const handleSave = () => {
        if (validateForm()){ 
            
            saveRegistration();
        }
        else setOpenStatus(true);
    };

    const saveRegistration = () => {
        setOpenStatus(false);

        if (props.registration === null || props.registration === undefined) {
            saveNewRegistration(props.atheleteId);
        } else {
            updateExistingRegistration(
                props.atheleteId,
                props.registration?.registrationID!
            );
        }

        resetFormContext();

        props.handleClose();
    };

    const handleChangeType = (event: any) => {
        setRegistrationType(Number.parseInt(event.target.value));
    };

    const handleChangeBuilding = (b: any) => {
        let selectedBuilding = buildings
            ?.filter((a) => a.buildingId === Number.parseInt(b.target.value))
            .pop();
        setBuildingName(selectedBuilding?.buildingName!);
    };

    const handleCloseStatus = () => {
        setOpenStatus(false);
    };

    const buildingItems = (): ReactNode => {
        let nodes = (
            <MenuItem key={0} value={0}>
                Please select a value
            </MenuItem>
        );

        if (filteredBuildings?.length! > 0) {
            let new_nodes =
                filteredBuildings &&
                filteredBuildings!.map((b: Building, i: number) => {
                    return (
                        <MenuItem key={b.buildingId} value={b.buildingId}>
                            {b.buildingName}
                        </MenuItem>
                    );
                });

            return new_nodes;
        }

        //make sure we always have a building list
        //if we did not initialize this we assume its olympic village

        if (filteredBuildings?.length === 0 || !filteredBuildings) {
            let new_nodes = buildings
                ?.filter((b) => b.buildingLocation === "Olympic Village")
                .map((b: Building, i: number) => {
                    return (
                        <MenuItem key={b.buildingId} value={b.buildingId}>
                            {b.buildingName}
                        </MenuItem>
                    );
                });

            return new_nodes;
        }

        return nodes;
    };

    const validateForm = (): boolean => {
        if (
            registrationType === 0 ||
            registrationType === 1 ||
            registrationType === 2
        ) {
            if (
                buildingName !== "" &&
                buildingName !== undefined &&
                room !== "" &&
                room !== undefined
            )
                return true;
            else return false;
        }

        if (registrationType === 3) {
            if (
                buildingName !== "" &&
                buildingName !== undefined &&
                city !== "" &&
                city !== undefined &&
                address !== "" &&
                address !== undefined
            )
                return true;
            else return false;
        }

        if (registrationType === 4) {
            if (
                city !== "" &&
                city !== undefined &&
                address !== "" &&
                address !== undefined
            )
                return true;
            else return false;
        }

        return true;
    };

    const validateField = (fieldName: string): boolean => {
        if (
            registrationType === 0 ||
            registrationType === 1 ||
            registrationType === 2
        ) {
            switch (fieldName) {
                case "buildingName":
                    if (buildingName !== "" && buildingName !== undefined)
                        return true;
                    else return false;
                case "room":
                    if (room !== "" && room !== undefined) return true;
                    else return false;
                case "toDate":
                    if (toDate !== undefined)
                        if (toDate >= fromDate!) return true;
                        else return false;
                    else return false;
            }
        }

        if (registrationType === 3) {
            switch (fieldName) {
                case "address":
                    if (address !== "" && address !== undefined) return true;
                    else return false;
                case "city":
                    if (city !== "" && city !== undefined) return true;
                    else return false;
                case "buildingName":
                    if (buildingName !== "" && buildingName !== undefined)
                        return true;
                    else return false;
            }
        }

        if (registrationType === 4) {
            switch (fieldName) {
                case "address":
                    if (address !== "" && address !== undefined) return true;
                    else return false;
                case "city":
                    if (city !== "" && city !== undefined) return true;
                    else return false;
            }
        }

        return true;
    };

    const saveButtonValidation = (): boolean => {
        if (fromDate && toDate)
            if (
                new Date(fromDate) > new Date(2021, 5, 30) &&
                new Date(toDate) > new Date(2021, 5, 30) &&
                new Date(fromDate) < new Date(2021, 8, 1) &&
                new Date(toDate) < new Date(2021, 8, 1)
            ) {
                return true;
            }
        return false;
    };

    const validationMessage = (fieldName: string): string => {
        if (
            registrationType === 0 ||
            registrationType === 1 ||
            registrationType === 2
        ) {
            switch (fieldName) {
                case "buildingName":
                    if (buildingName !== "" && buildingName !== undefined)
                        return "";
                    else return "Value required";
                case "room":
                    if (room !== "" && room !== undefined) return "";
                    else return "Value required";
                case "toDate":
                    if (toDate !== undefined)
                        if (toDate >= fromDate!) return "";
                        else return "To date is before from date";
                    else return "Value required";
                default:
                    return "";
            }
        }

        if (registrationType === 3) {
            switch (fieldName) {
                case "address":
                    if (address !== "" && address !== undefined) return "";
                    else return "Value required";
                case "city":
                    if (city !== "" && city !== undefined) return "";
                    else return "Value required";
                case "buildingName":
                    if (buildingName !== "" && buildingName !== undefined)
                        return "";
                    else return "Value required";
            }
        }

        if (registrationType === 4) {
            switch (fieldName) {
                case "address":
                    if (address !== "" && address !== undefined) return "";
                    else return "Value required";
                case "city":
                    if (city !== "" && city !== undefined) return "";
                    else return "Value required";
            }
        }

        return "";
    };

    return (
        <Container maxWidth="md">
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props.isOpen}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.isOpen}>
                    <Container maxWidth="md">
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Add a new registration{" "}
                                    {props.athletes
                                        ? "for multiple athletes"
                                        : ""}
                                </Typography>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <form>
                                        <Grid container spacing={4}>
                                            <Grid item xs={6}>
                                                <Select
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Building"
                                                    value={registrationType}
                                                    onChange={handleChangeType}
                                                >
                                                    <MenuItem value={"0"}>
                                                        Olympic Village
                                                    </MenuItem>
                                                    <MenuItem value={"1"}>
                                                        Cycling Village
                                                    </MenuItem>
                                                    <MenuItem value={"2"}>
                                                        Sailing Village
                                                    </MenuItem>
                                                    <MenuItem value={"3"}>
                                                        Hotel
                                                    </MenuItem>
                                                    <MenuItem value={"4"}>
                                                        Private
                                                    </MenuItem>
                                                </Select>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div
                                                    hidden={
                                                        registrationType ===
                                                            4 ||
                                                        registrationType === 3
                                                    }
                                                >
                                                    <Select
                                                        error={
                                                            !validateField(
                                                                "buildingName"
                                                            )
                                                        }
                                                        fullWidth
                                                        label="Select a building"
                                                        variant="outlined"
                                                        value={
                                                            buildingName
                                                                ? buildings
                                                                      ?.filter(
                                                                          (b) =>
                                                                              b.buildingName ===
                                                                              buildingName
                                                                      )
                                                                      .pop()
                                                                      ?.buildingId
                                                                : 1
                                                        }
                                                        onChange={
                                                            handleChangeBuilding
                                                        }
                                                    >
                                                        {buildingItems()}
                                                    </Select>
                                                </div>
                                                <div
                                                    hidden={
                                                        registrationType !==
                                                        RegistrationType.NUMBER_3
                                                    }
                                                >
                                                    <TextField
                                                        helperText={validationMessage(
                                                            "buildingName"
                                                        )}
                                                        error={
                                                            !validateField(
                                                                "buildingName"
                                                            )
                                                        }
                                                        fullWidth
                                                        label="Building name"
                                                        value={buildingName}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.labelRoot,
                                                                focused:
                                                                    classes.labelFocused,
                                                            },
                                                        }}
                                                        onChange={(v) => {
                                                            setBuildingName(
                                                                v.currentTarget
                                                                    .value
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    helperText={validationMessage(
                                                        "address"
                                                    )}
                                                    error={
                                                        !validateField(
                                                            "address"
                                                        )
                                                    }
                                                    fullWidth
                                                    size="small"
                                                    label="Address"
                                                    value={address}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                            focused:
                                                                classes.labelFocused,
                                                        },
                                                    }}
                                                    onChange={(v) => {
                                                        setAddress(
                                                            v.currentTarget
                                                                .value
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    helperText={validationMessage(
                                                        "city"
                                                    )}
                                                    error={
                                                        !validateField("city")
                                                    }
                                                    fullWidth
                                                    label="City"
                                                    value={city}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                            focused:
                                                                classes.labelFocused,
                                                        },
                                                    }}
                                                    onChange={(v) => {
                                                        setCity(
                                                            v.currentTarget
                                                                .value
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    fullWidth
                                                    label="Postal Code"
                                                    value={postalCode}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                            focused:
                                                                classes.labelFocused,
                                                        },
                                                    }}
                                                    onChange={(v) => {
                                                        setPostalCode(
                                                            v.currentTarget
                                                                .value
                                                        );
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    label="Floor"
                                                    value={floor}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                            focused:
                                                                classes.labelFocused,
                                                        },
                                                    }}
                                                    onChange={(v) => {
                                                        setFloor(
                                                            v.currentTarget
                                                                .value
                                                        );
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <TextField
                                                    helperText={validationMessage(
                                                        "room"
                                                    )}
                                                    error={
                                                        !validateField("room")
                                                    }
                                                    fullWidth
                                                    size="small"
                                                    label="Room no."
                                                    value={room}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                            focused:
                                                                classes.labelFocused,
                                                        },
                                                    }}
                                                    onChange={(v) => {
                                                        setRoom(
                                                            v.currentTarget
                                                                .value
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type="date"
                                                    value={moment(
                                                        fromDate
                                                    ).format("yyyy-MM-DD")}
                                                    fullWidth
                                                    label="From date"
                                                    InputProps={{
                                                        inputProps: {
                                                            min: "2021-07-01",
                                                            max: "2021-09-01",
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                            focused:
                                                                classes.labelFocused,
                                                        },
                                                    }}
                                                    onChange={(v) => {
                                                        setFromDate(v.currentTarget.value)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    helperText={validationMessage(
                                                        "toDate"
                                                    )}
                                                    error={
                                                        !validateField("toDate")
                                                    }
                                                    type="date"
                                                    value={moment(
                                                        toDate
                                                    ).format("yyyy-MM-DD")}
                                                    fullWidth
                                                    label="To date"
                                                    InputProps={{
                                                        inputProps: {
                                                            min: "2021-07-01",
                                                            max: "2021-09-01",
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                            focused:
                                                                classes.labelFocused,
                                                        },
                                                    }}
                                                    onChange={(v) => {
                                                        setToDate(v.currentTarget.value)  
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Comments"
                                                    defaultValue={comments}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                            focused:
                                                                classes.labelFocused,
                                                        },
                                                    }}
                                                    onChange={(v) => {
                                                        setComments(
                                                            v.currentTarget
                                                                .value
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </form>
                                </MuiPickersUtilsProvider>
                            </CardContent>
                            <CardActions>
                                <Button
                                    color="primary"
                                    size="medium"
                                    onClick={handleSave}
                                    disabled={!saveButtonValidation()}
                                >
                                    Save registration
                                </Button>
                                <Button
                                    color="primary"
                                    size="medium"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </CardActions>
                        </Card>
                    </Container>
                </Fade>
            </Modal>

            <Modal
                open={openStatus}
                onClose={handleCloseStatus}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >
                <Container>
                    <Card className={classes.root}>
                        <CardContent>
                            <p>
                                This registration is not complete. Some of the
                                required fields are not filled out.{" "}
                            </p>

                            <p>
                                We allow saving of incomplete registrations, but
                                the registrations will be marked accordingly and
                                will not fullfill the ITA requirements.{" "}
                            </p>

                            <p>
                                You can add the required fields now, or revisit
                                this registration later and fill out the missing
                                information.{" "}
                            </p>
                        </CardContent>
                        <CardActions>
                            <Button
                                color="primary"
                                size="medium"
                                onClick={() => saveRegistration()}
                            >
                                Save
                            </Button>
                            <Button
                                color="secondary"
                                size="medium"
                                onClick={handleCloseStatus}
                            >
                                Continue editing
                            </Button>
                        </CardActions>
                    </Card>
                </Container>
            </Modal>
        </Container>
    );
}

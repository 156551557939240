/* tslint:disable */
/* eslint-disable */
/**
 * ITA_Rooming_Scaffold
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicationInfo
 */
export interface ApplicationInfo {
    /**
     * 
     * @type {string}
     * @memberof ApplicationInfo
     */
    country?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationInfo
     */
    numberOfAthletes?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationInfo
     */
    incompleteRegistrations?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationInfo
     */
    athletesWithNoRegistrations?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationInfo
     */
    athletesWithOnlyCompleteRegistrations?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationInfo
     */
    athletesWithIncompleteRegistrations?: number;
}

export function ApplicationInfoFromJSON(json: any): ApplicationInfo {
    return ApplicationInfoFromJSONTyped(json, false);
}

export function ApplicationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'country': !exists(json, 'country') ? undefined : json['country'],
        'numberOfAthletes': !exists(json, 'numberOfAthletes') ? undefined : json['numberOfAthletes'],
        'incompleteRegistrations': !exists(json, 'incompleteRegistrations') ? undefined : json['incompleteRegistrations'],
        'athletesWithNoRegistrations': !exists(json, 'athletesWithNoRegistrations') ? undefined : json['athletesWithNoRegistrations'],
        'athletesWithOnlyCompleteRegistrations': !exists(json, 'athletesWithOnlyCompleteRegistrations') ? undefined : json['athletesWithOnlyCompleteRegistrations'],
        'athletesWithIncompleteRegistrations': !exists(json, 'athletesWithIncompleteRegistrations') ? undefined : json['athletesWithIncompleteRegistrations'],
    };
}

export function ApplicationInfoToJSON(value?: ApplicationInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country': value.country,
        'numberOfAthletes': value.numberOfAthletes,
        'incompleteRegistrations': value.incompleteRegistrations,
        'athletesWithNoRegistrations': value.athletesWithNoRegistrations,
        'athletesWithOnlyCompleteRegistrations': value.athletesWithOnlyCompleteRegistrations,
        'athletesWithIncompleteRegistrations': value.athletesWithIncompleteRegistrations,
    };
}



/* tslint:disable */
/* eslint-disable */
/**
 * ITA_Rooming_Scaffold
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Athlete,
    AthleteFromJSON,
    AthleteToJSON,
} from '../models';

export interface ApiSearchAdvancedGetRequest {
    searchTerm?: string;
}

export interface ApiSearchGetRequest {
    searchTerm?: string;
}

/**
 * 
 */
export class SearchApi extends runtime.BaseAPI {

    /**
     */
    async apiSearchAdvancedGetRaw(requestParameters: ApiSearchAdvancedGetRequest): Promise<runtime.ApiResponse<Array<Athlete>>> {
        const queryParameters: any = {};

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Search/advanced`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AthleteFromJSON));
    }

    /**
     */
    async apiSearchAdvancedGet(requestParameters: ApiSearchAdvancedGetRequest): Promise<Array<Athlete>> {
        const response = await this.apiSearchAdvancedGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiSearchGetRaw(requestParameters: ApiSearchGetRequest): Promise<runtime.ApiResponse<Array<Athlete>>> {
        const queryParameters: any = {};

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AthleteFromJSON));
    }

    /**
     */
    async apiSearchGet(requestParameters: ApiSearchGetRequest): Promise<Array<Athlete>> {
        const response = await this.apiSearchGetRaw(requestParameters);
        return await response.value();
    }

}

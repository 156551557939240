import { Checkbox, TableCell, TableHead, TableRow } from '@material-ui/core'
import React from 'react'

interface AtheleteListTableHeadProps{
    isAllSelected: boolean, 
    handleCheckClick : (checked : boolean) => void
}

export default function AthleteListTableHead(props : AtheleteListTableHeadProps) {
    return (
        <React.Fragment>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Checkbox checked={props.isAllSelected} onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => props.handleCheckClick(checked)}></Checkbox>
                    </TableCell>
                    <TableCell>Athlete name</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">Gender</TableCell>
                    <TableCell align="center">Sport</TableCell>
                    <TableCell align="center">Accreditation number</TableCell>
                </TableRow>
            </TableHead>
        </React.Fragment>
    )
}

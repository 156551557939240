import { useAccount, useMsal } from "@azure/msal-react";
import {
    Drawer,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import DownloadIcon from "@material-ui/icons/ArrowDownward";
import RestorePageIcon from "@material-ui/icons/RestorePage";
import { AdminContext } from "../../../context/AdminContext";
import SendNotificationDialog from "./SendNotificationDialog";

interface ApplicationDrawerProps {
    showDrawer: boolean;
    toogleDrawer: () => void;
}

export default function AdminApplicationDrawer(props: ApplicationDrawerProps) {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [showNotificationDialog, setShowNotificationDialog] = useState(false);
    const [hideResetCache, setHideResetCache] = useState(true);

    const { downloadRegistrationsCSV, resetCache } = useContext(AdminContext);

    const logout = () => instance.logoutPopup();

    const handleMenuClick = (whichOne: string) => {
        switch (whichOne) {
            case "logout":
                logout();
                break;
            case "export_athletes":
                downloadRegistrationsCSV();
                break;
            case "send_reminder":
                handleShowNotificationDialog();
                break;
        }
    };

    useEffect(() => {
        if (account) {
            debugger;
            if (account.username!.indexOf("george.stan") > -1) {
                setHideResetCache(false);
            }
        }
    // eslint-disable-next-line
    }, []);

    const handleResetCacheClick = () => {
        resetCache();
        props.toogleDrawer();
    };

    const handleShowNotificationDialog = () => {
        setShowNotificationDialog(true);
    };

    const handleCloseNotificationDialog = (event: any) => {
        setShowNotificationDialog(false);
    };

    return (
        <Drawer open={props.showDrawer} onClose={props.toogleDrawer}>
            <ListItem button onClick={() => handleMenuClick("export_athletes")}>
                <ListItemIcon>
                    <DownloadIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Export registrations" />
            </ListItem>
            
            {/*

            <ListItem button onClick={() => handleMenuClick("send_reminder")}>
                <ListItemIcon>
                    <NotificationsIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Send reminder" />
            </ListItem>

            */}

            <div hidden={hideResetCache}>
                <ListItem
                    button
                    onClick={() => {
                        handleResetCacheClick();
                    }}
                >
                    <ListItemIcon>
                        <RestorePageIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Reset cache" />
                </ListItem>
            </div>
            <ListItem button onClick={() => handleMenuClick("logout")}>
                <ListItemIcon>
                    <LogoutIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Logout" secondary={account?.name} />
            </ListItem>

            <SendNotificationDialog
                open={showNotificationDialog}
                handleClose={handleCloseNotificationDialog}
            ></SendNotificationDialog>
        </Drawer>
    );
}

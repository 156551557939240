import { AccountInfo, SilentRequest } from "@azure/msal-browser";
import React, { useContext, useEffect, useState } from "react";
import { Configuration } from "../runtime";
import { MsalContext } from "@azure/msal-react";
import { apiScopes, apiAuthority, apiBasePath } from "../config";

type AppContextType = {
    setIsAdmin: (b: boolean) => void;
    isAdmin: boolean;
};

export const AppContext = React.createContext<AppContextType>({
    setIsAdmin: (b: boolean) => null,
    isAdmin: false,
});

AppContext.displayName = "ApplicationContext";

export function useAppContext() {
    const msalContext = useContext(MsalContext);
    const [isAdmin, setIsAdmin] = useState(false);

    // eslint-disable-next-line
    const silentRequest = () => {
        if (msalContext.accounts[0]) {
            const accessTokenRequest: SilentRequest = {
                scopes: apiScopes,
                account: msalContext.accounts[0] as AccountInfo,
                authority: apiAuthority,
            } as SilentRequest;

            return msalContext.instance
                .acquireTokenSilent(accessTokenRequest)
                .then((response) => {
                    const accessToken = response.accessToken;
                    let c: Configuration = new Configuration({
                        accessToken: accessToken,
                        basePath: apiBasePath,
                    });
                    return c;
                });
        }
        return Promise.reject();
    };

    //the ideea here is that we get the isadmin from session storage if available

    useEffect(() => {
        if (sessionStorage.getItem("isAdmin")) {
            try {
                let s_isAdmin = sessionStorage.getItem("isAdmin");
                if (s_isAdmin === "true") {
                    setIsAdmin(true);
                }
                if (s_isAdmin === "false") {
                    setIsAdmin(false);
                }
            } catch (exception) {}
        }
    }, []);

    return {
        setIsAdmin,
        isAdmin,
    };
}

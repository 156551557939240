import { Box, IconButton, List, ListItem, ListItemText, Popover, Toolbar, Tooltip, Typography } from '@material-ui/core'
import React from 'react'
import FilterListIcon from '@material-ui/icons/FilterList';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';


interface AthleteListTableTitleProps {
    title: string,
    filteringHandler: (filter: string) => void
}

export default function AthleteListTableTitle(props: AthleteListTableTitleProps) {

    const handleFilterChange = (filter : string) => {
        props.filteringHandler(filter);
    }

    return (
        <React.Fragment>
            <Toolbar style={{ justifyContent: "space-between" }}>
                <Typography variant="h6" id="tableTitle" component="div">
                    {props.title}
                </Typography>
                <Tooltip title="Filter athlete list">
                    <PopupState variant="popover" popupId="demo-popup-popover">
                        {(popupState: any) => (
                            <div>
                                <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
                                    <FilterListIcon />
                                </IconButton>
                                <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Box p={2}>
                                        <List component="nav" aria-label="main mailbox folders">
                                            <ListItem button onClick={() => handleFilterChange("All athletes")}>
                                                <ListItemText primary="All Athletes" />
                                            </ListItem>
                                            <ListItem button>
                                                <ListItemText primary="Athletes with no registrations" onClick={() => handleFilterChange("No registrations")} />
                                            </ListItem>
                                            <ListItem button>
                                                <ListItemText primary="Athletes with incomplete registrations" onClick={() => handleFilterChange("Incomplete registrations")} />
                                            </ListItem>
                                            <ListItem button>
                                                <ListItemText primary="Athletes with complete registrations" onClick={() => handleFilterChange("Complete registrations")} />
                                            </ListItem>
                                        </List>
                                    </Box>
                                </Popover>
                            </div>
                        )}
                    </PopupState>
                </Tooltip>
            </Toolbar>

        </React.Fragment>
    )
}

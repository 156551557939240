import {
    createStyles,
    Grid,
    makeStyles,
    Paper,
    Theme,
    Typography,
    Box,
    Tooltip,
} from "@material-ui/core";

import WarningIcon from "@material-ui/icons/Warning";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import { yellow, green } from "@material-ui/core/colors";

import { Registration, RegistrationType } from "../../models";
import AthleteDialog from "./AtheleteDialog/AthleteDialog";
import React, { useContext, useState } from "react";
import moment from "moment";
import { RegistrationContext } from "../../context/RegistrationDialogContext";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import { UserContext } from "../../context/UserContext";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textRed: {
            color: "#ab003c",
        },
        textGreen: {
            color: "#357a38",
        },
        paper: {
            padding: theme.spacing(2),
        },
    })
);

interface RegistrationCardProps {
    registration: Registration;
}

export default function RegistrationCard(props: RegistrationCardProps) {
    const classes = useStyles();
    const { setDeleteRegistrationId } = useContext(UserContext);
    const { createRegistrationContext } = useContext(RegistrationContext);
    const [showDialog, setShowDialog] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const handleDelete = (id: number | undefined) => {
        if (id) {
            setDeleteRegistrationId(id);
        }
    };
    // eslint-disable-next-line
    const handleEdit = () => {
        createRegistrationContext(props.registration);
        setShowDialog(true);
    };

    const handleDialogClose = () => {
        setShowDialog(false);
    };

    const handleConfirmationCancel = () => {
        setShowConfirmationDialog(false);
    };

    const handleConfirmationDelete = () => {
        setShowConfirmationDialog(false);
        handleDelete(props.registration.registrationID);
    };

    const displayAddress = () => {
        let addr = props.registration.streetAddress || "";
        addr += addr && props.registration.locality ? ", " : "";
        addr += props.registration.locality || "";
        addr += addr && props.registration.administrativeArea ? ", " : "";
        addr += props.registration.administrativeArea
            ? `(${props.registration.administrativeArea})`
            : "";
        addr += addr && props.registration.postalCode ? ", " : "";
        addr += props.registration.postalCode || "";
        addr += addr && props.registration.buildingName ? ", " : "";
        addr += props.registration.buildingName || "";

        if (props.registration.buildingName) {
            addr += " / ";
            switch (props.registration.registrationType) {
                case RegistrationType.NUMBER_0:
                    addr += "Olympic Village";
                    break;
                case RegistrationType.NUMBER_1:
                    addr += "Olympic Cycling Village";
                    break;
                case RegistrationType.NUMBER_2:
                    addr += "Olympic Sailing Village";
                    break;
                case RegistrationType.NUMBER_3:
                    addr += "Hotel";
                    break;
                case RegistrationType.NUMBER_4:
                    addr += "Private";
                    break;
            }
        }

        return addr;
    };

    return (
        <div>
            <br />
            <Paper className={classes.paper} elevation={2}>
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography gutterBottom variant="subtitle1">
                            #{props.registration.registrationID}
                        </Typography>

                        <div hidden={!props.registration.isComplete}>
                            <Tooltip title="Complete registration">
                                <DoneOutlineIcon
                                    fontSize="small"
                                    color="disabled"
                                    style={{ color: green[500] }}
                                ></DoneOutlineIcon>
                            </Tooltip>
                        </div>

                        <div hidden={props.registration.isComplete}>
                            <Tooltip title="Incomplete registration">
                                <WarningIcon
                                    fontSize="small"
                                    color="disabled"
                                    style={{ color: yellow[500] }}
                                ></WarningIcon>
                            </Tooltip>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                    {displayAddress()}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    Room no: {props.registration.roomNumber},
                                    Floor: {props.registration.floor}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    From date:{" "}
                                    <span className={classes.textGreen}>
                                        {moment(
                                            props.registration.fromDate
                                        ).format("MMM Do YY")}
                                    </span>
                                    <br />
                                    To date:{" "}
                                    <span className={classes.textRed}>
                                        {moment(
                                            props.registration.toDate
                                        ).format("MMM Do YY")}
                                    </span>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <Box justifyContent="flex-end" display="flex">
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

            <DeleteConfirmationDialog
                visible={showConfirmationDialog}
                handleClose={handleConfirmationCancel}
                handleConfirm={handleConfirmationDelete}
            ></DeleteConfirmationDialog>

            <AthleteDialog
                isOpen={showDialog}
                handleClose={handleDialogClose}
                atheleteId={[props.registration?.athleteId!]}
                registration={props.registration}
            ></AthleteDialog>
        </div>
    );
}

import {
    Box,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableContainer,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { pageSize } from "../../../config";
import React, { useContext } from "react";
import { AdminContext } from "../../../context/AdminContext";
import { RegistrationAdminInfo } from "../../../models";
import RegistrationsListTableHead from "./RegistrationsListTableHead";
import RegistrationsListTableTitle from "./RegistrationsListTableTitle";
import RegistrationsRowPlaceholder from "./RegistrationsRowPlaceholder";
import RegistrationsListTableRow from "./RegistrationsListTableRow";


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    bulkActionDialog: {
        padding: "15px",
        marginBottom: "10px",
    },
}));

export default function RegistrationsList() {
    const classes = useStyles();

    const {
        registrations,
        registrationsCount,
        setRegistrationsPage,
        isLoading,
    } = useContext(AdminContext);

    return (
        <div>
            <RegistrationsListTableTitle />
            <Paper style={{ maxHeight: "90%", overflow: "auto" }}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <RegistrationsListTableHead></RegistrationsListTableHead>
                        {!isLoading && (
                            <TableBody>
                                {registrations &&
                                    registrations.map(
                                        (
                                            row: RegistrationAdminInfo,
                                            index: number
                                        ) => (
                                            <RegistrationsListTableRow index={index} row={row}></RegistrationsListTableRow>
                                        )
                                    )}
                            </TableBody>
                        )}
                    </Table>
                    {isLoading && (
                        <RegistrationsRowPlaceholder></RegistrationsRowPlaceholder>
                    )}
                </TableContainer>
            </Paper>
            <Box my={2} display="flex" justifyContent="center">
                <Pagination
                    count={Math.floor(registrationsCount / pageSize) + 1}
                    shape="rounded"
                    color="primary"
                    onChange={(ev, page) =>
                        setRegistrationsPage({
                            pageSize: pageSize,
                            pageNumber: page - 1,
                        })
                    }
                />
            </Box>
        </div>
    );
}

/* tslint:disable */
/* eslint-disable */
/**
 * ITA_Rooming_Scaffold
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RegistrationType,
    RegistrationTypeFromJSON,
    RegistrationTypeFromJSONTyped,
    RegistrationTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Registration
 */
export interface Registration {
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    createdBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Registration
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Registration
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Registration
     */
    registrationID?: number;
    /**
     * 
     * @type {RegistrationType}
     * @memberof Registration
     */
    registrationType?: RegistrationType;
    /**
     * 
     * @type {number}
     * @memberof Registration
     */
    athleteId?: number;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    streetAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    buildingName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    locality?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    administrativeArea?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    roomNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    floor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    fromDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    toDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    additionalComments?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Registration
     */
    isComplete?: boolean;
}

export function RegistrationFromJSON(json: any): Registration {
    return RegistrationFromJSONTyped(json, false);
}

export function RegistrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Registration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (new Date(json['modifiedDate'])),
        'registrationID': !exists(json, 'registrationID') ? undefined : json['registrationID'],
        'registrationType': !exists(json, 'registrationType') ? undefined : RegistrationTypeFromJSON(json['registrationType']),
        'athleteId': !exists(json, 'athleteId') ? undefined : json['athleteId'],
        'streetAddress': !exists(json, 'streetAddress') ? undefined : json['streetAddress'],
        'buildingName': !exists(json, 'buildingName') ? undefined : json['buildingName'],
        'locality': !exists(json, 'locality') ? undefined : json['locality'],
        'administrativeArea': !exists(json, 'administrativeArea') ? undefined : json['administrativeArea'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'roomNumber': !exists(json, 'roomNumber') ? undefined : json['roomNumber'],
        'floor': !exists(json, 'floor') ? undefined : json['floor'],
        'fromDate': !exists(json, 'fromDate') ? undefined : json['fromDate'],
        'toDate': !exists(json, 'toDate') ? undefined : json['toDate'],
        'additionalComments': !exists(json, 'additionalComments') ? undefined : json['additionalComments'],
        'isComplete': !exists(json, 'isComplete') ? undefined : json['isComplete'],
    };
}

export function RegistrationToJSON(value?: Registration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdBy': value.createdBy,
        'modifiedBy': value.modifiedBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate.toISOString()),
        'registrationID': value.registrationID,
        'registrationType': RegistrationTypeToJSON(value.registrationType),
        'athleteId': value.athleteId,
        'streetAddress': value.streetAddress,
        'buildingName': value.buildingName,
        'locality': value.locality,
        'administrativeArea': value.administrativeArea,
        'postalCode': value.postalCode,
        'roomNumber': value.roomNumber,
        'floor': value.floor,
        'fromDate': value.fromDate,
        'toDate': value.toDate,
        'additionalComments': value.additionalComments,
        'isComplete': value.isComplete,
    };
}



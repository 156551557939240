/* tslint:disable */
/* eslint-disable */
/**
 * ITA_Rooming_Scaffold
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Discipline
 */
export interface Discipline {
    /**
     * 
     * @type {number}
     * @memberof Discipline
     */
    disciplineID?: number;
    /**
     * 
     * @type {string}
     * @memberof Discipline
     */
    sportCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Discipline
     */
    sportName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Discipline
     */
    disciplineName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Discipline
     */
    disciplineCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Discipline
     */
    fullName?: string | null;
}

export function DisciplineFromJSON(json: any): Discipline {
    return DisciplineFromJSONTyped(json, false);
}

export function DisciplineFromJSONTyped(json: any, ignoreDiscriminator: boolean): Discipline {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'disciplineID': !exists(json, 'disciplineID') ? undefined : json['disciplineID'],
        'sportCode': !exists(json, 'sportCode') ? undefined : json['sportCode'],
        'sportName': !exists(json, 'sportName') ? undefined : json['sportName'],
        'disciplineName': !exists(json, 'disciplineName') ? undefined : json['disciplineName'],
        'disciplineCode': !exists(json, 'disciplineCode') ? undefined : json['disciplineCode'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
    };
}

export function DisciplineToJSON(value?: Discipline | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'disciplineID': value.disciplineID,
        'sportCode': value.sportCode,
        'sportName': value.sportName,
        'disciplineName': value.disciplineName,
        'disciplineCode': value.disciplineCode,
        'fullName': value.fullName,
    };
}



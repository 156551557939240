import {
    Dialog,
    DialogTitle,
    Grid,
    makeStyles,
    TextField,
    Theme,
    Typography,
    createStyles,
    Button,
} from "@material-ui/core";
import React, { useContext, useState } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { AdminContext } from "../../../context/AdminContext";
import { Country } from "../../../models/Country";
import NotificationDialog from "./NotificationDialog";
import UsersList from "./UsersList";
import SelectedRegistrations from "./SelectedRegistrations";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        title: {
            fontSize: 14,
        },
        yellow: {
            backgroundColor: "#fdffbf",
            boxShadow: theme.shadows[5],
            margin: "0.5rem",
            padding: "15px",
        },
        green: {
            backgroundColor: "#dbffd1",
            boxShadow: theme.shadows[5],
            margin: "0.5rem",
            padding: "15px",
        },
        red: {
            backgroundColor: "#fe6269",
            boxShadow: theme.shadows[5],
            margin: "0.5rem",
            padding: "15px",
        },
        blue: {
            backgroundColor: "#d1ddff",
            boxShadow: theme.shadows[5],
            margin: "0.5rem",
            padding: "15px",
        },
        list: {
            width: 650,
        },
        card_content: {
            height: 75,
        },
        card_value: {},
    })
);

interface SendNotificationDialogProps {
    handleClose: (event: Event) => void;
    open: boolean;
}

export default function SendNotificationDialog(
    props: SendNotificationDialogProps
) {
    const classes = useStyles();

    const [showSendNotificaiton, setShowSendNotificaiton] = useState(false);

    const {
        countries,
        setSelectedCountry,
        selectedCountryData,
        selectedRegistrations,
        setSelectedRegistrations,
        selectedCountry,
    } = useContext(AdminContext);

    const countrySelected = (event: any, newValue: Country | null) => {
        if (newValue){
            setSelectedCountry(newValue);
            setSelectedRegistrations([]);
        } 
    };

    return (
        <Dialog
            onClose={props.handleClose}
            aria-labelledby="simple-dialog-title"
            open={props.open}
            maxWidth={"md"}
            fullWidth={true}
        >
            <DialogTitle id="simple-dialog-title">
                <Grid container>
                    <Grid item xs={12} md={9}>
                        <Typography variant="h4" gutterBottom>
                            Country statistics{" "}
                            {selectedCountryData
                                ? "- " + selectedCountryData.country
                                : ""}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Autocomplete
                            style={{ padding: "5px" }}
                            fullWidth={true}
                            id="combo-box-demo"
                            options={countries}
                            defaultValue={selectedCountry}
                            onChange={countrySelected}
                            getOptionLabel={(option) => option.countryName!}
                            //getOptionLabel={(option) => option.countryName!}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Country"
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>

                    <Grid xs={12} md={6}>
                        <div className={classes.blue}>
                            Total number of atletes:{" "}
                            {selectedCountryData?.numberOfAthletes!}
                        </div>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <div className={classes.green}>
                            Athletes with complete registrations:{" "}
                            {
                                selectedCountryData?.athletesWithOnlyCompleteRegistrations!
                            }
                        </div>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <div className={classes.yellow}>
                            Athletes with incomplete registrations:{" "}
                            {
                                selectedCountryData?.athletesWithIncompleteRegistrations!
                            }
                        </div>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <div className={classes.red}>
                            Athletes with no registrations:{" "}
                            {selectedCountryData?.athletesWithNoRegistrations!}
                        </div>
                    </Grid>

                    <Grid item xs={12} md={9} style={{ marginTop: "40px" }}>
                        <Typography variant="h4" gutterBottom>
                            NOC users for country
                        </Typography>
                    </Grid>

                    <Grid xs={12}>
                        <UsersList></UsersList>
                    </Grid>

                    <Grid
                        hidden={selectedRegistrations?.length === 0}
                        item
                        xs={12}
                        md={9}
                        style={{ marginTop: "40px" }}
                    >
                        <Typography variant="h4" gutterBottom>
                            Athletes selected for country
                        </Typography>
                    </Grid>

                    <Grid hidden={selectedRegistrations?.length === 0} xs={12}>
                        <SelectedRegistrations></SelectedRegistrations>
                    </Grid>

                    <Grid item xs={12} style={{ padding: "20px" }}>
                        <Button
                            disabled={
                                selectedCountryData &&
                                selectedCountryData?.country &&
                                selectedCountryData?.country?.length > 0
                                    ? false
                                    : true
                            }
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setShowSendNotificaiton(true);
                            }}
                        >
                            Send reminder
                        </Button>

                        <NotificationDialog
                            selectedAthletes={selectedRegistrations!}
                            countryId={
                                selectedCountryData
                                    ? selectedCountryData.country!
                                    : ""
                            }
                            countryName={
                                selectedCountryData
                                    ? selectedCountryData.country!
                                    : ""
                            }
                            isOpen={showSendNotificaiton}
                            closeNotificationDialog={() => {
                                setShowSendNotificaiton(false);
                            }}
                        ></NotificationDialog>
                    </Grid>
                </Grid>
            </DialogTitle>
        </Dialog>
    );
}

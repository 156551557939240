/* tslint:disable */
/* eslint-disable */
/**
 * ITA_Rooming_Scaffold
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Country,
    CountryFromJSON,
    CountryFromJSONTyped,
    CountryToJSON,
    Discipline,
    DisciplineFromJSON,
    DisciplineFromJSONTyped,
    DisciplineToJSON,
    Gender,
    GenderFromJSON,
    GenderFromJSONTyped,
    GenderToJSON,
    Registration,
    RegistrationFromJSON,
    RegistrationFromJSONTyped,
    RegistrationToJSON,
} from './';

/**
 * 
 * @export
 * @interface Athlete
 */
export interface Athlete {
    /**
     * 
     * @type {number}
     * @memberof Athlete
     */
    athleteID?: number;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    accreditationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    middleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    fullName?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof Athlete
     */
    gender?: Gender;
    /**
     * 
     * @type {number}
     * @memberof Athlete
     */
    disciplineId?: number;
    /**
     * 
     * @type {Discipline}
     * @memberof Athlete
     */
    discipline?: Discipline;
    /**
     * 
     * @type {Array<Registration>}
     * @memberof Athlete
     */
    registrations?: Array<Registration> | null;
    /**
     * 
     * @type {number}
     * @memberof Athlete
     */
    countryId?: number;
    /**
     * 
     * @type {Country}
     * @memberof Athlete
     */
    country?: Country;
}

export function AthleteFromJSON(json: any): Athlete {
    return AthleteFromJSONTyped(json, false);
}

export function AthleteFromJSONTyped(json: any, ignoreDiscriminator: boolean): Athlete {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'athleteID': !exists(json, 'athleteID') ? undefined : json['athleteID'],
        'accreditationNumber': !exists(json, 'accreditationNumber') ? undefined : json['accreditationNumber'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'middleName': !exists(json, 'middleName') ? undefined : json['middleName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'gender': !exists(json, 'gender') ? undefined : GenderFromJSON(json['gender']),
        'disciplineId': !exists(json, 'disciplineId') ? undefined : json['disciplineId'],
        'discipline': !exists(json, 'discipline') ? undefined : DisciplineFromJSON(json['discipline']),
        'registrations': !exists(json, 'registrations') ? undefined : (json['registrations'] === null ? null : (json['registrations'] as Array<any>).map(RegistrationFromJSON)),
        'countryId': !exists(json, 'countryId') ? undefined : json['countryId'],
        'country': !exists(json, 'country') ? undefined : CountryFromJSON(json['country']),
    };
}

export function AthleteToJSON(value?: Athlete | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'athleteID': value.athleteID,
        'accreditationNumber': value.accreditationNumber,
        'firstName': value.firstName,
        'middleName': value.middleName,
        'lastName': value.lastName,
        'fullName': value.fullName,
        'gender': GenderToJSON(value.gender),
        'disciplineId': value.disciplineId,
        'discipline': DisciplineToJSON(value.discipline),
        'registrations': value.registrations === undefined ? undefined : (value.registrations === null ? null : (value.registrations as Array<any>).map(RegistrationToJSON)),
        'countryId': value.countryId,
        'country': CountryToJSON(value.country),
    };
}



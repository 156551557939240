import React from 'react'
import { TableCell, TableHead, TableRow } from '@material-ui/core'


export default function RegistrationsListTableHead() {
    return (
        <React.Fragment>
        <TableHead>
            <TableRow>
                <TableCell></TableCell>
                <TableCell align="center">Athlete name</TableCell>
                <TableCell></TableCell>
                <TableCell align="center">Gender</TableCell>
                <TableCell align="center">Country</TableCell>
                <TableCell align="center">Accreditation number</TableCell>
                <TableCell align="center">Sport</TableCell>
                <TableCell align="center">Discipline</TableCell>
                <TableCell align="center">Registration Type</TableCell>
                <TableCell align="center">From date</TableCell>
                <TableCell align="center">To date</TableCell>
                <TableCell align="center">City</TableCell>
                <TableCell align="center">Address</TableCell>
                <TableCell align="center">Building name</TableCell>
                <TableCell align="center">Floor</TableCell>
                <TableCell align="center">Room number</TableCell>
                <TableCell align="center">Postal code</TableCell>
                <TableCell align="center">Comments</TableCell>
            </TableRow>
        </TableHead>
    </React.Fragment>
    )
}

import {
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    createStyles,
    Grid,
    makeStyles,
    Modal,
    TextField,
    Theme,
    Typography,
} from "@material-ui/core";

import MUIRichTextEditor from "mui-rte";
import { stateToHTML } from "draft-js-export-html";
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js'


import React, { useContext, useState, useEffect } from "react";
import { AdminContext } from "../../../context/AdminContext";
import { RegistrationAdminInfo } from "../../../models";

interface NotificationDialogProps {
    countryName: string;
    countryId: string;
    isOpen: boolean;
    selectedAthletes: RegistrationAdminInfo[];
    closeNotificationDialog: (b: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        root: {
            padding: "20px",
        },
    })
);

export default function NotificationDialog(props: NotificationDialogProps) {
    const classes = useStyles();

    const { selectedCountryUsers, sendNotificationForSelectedCountry } =
        useContext(AdminContext);

    const [message, setMessage] = useState("");
    const [recipients, setRecipients] = useState("");

    const [defaultContent, setDefaultContent] = useState("");

    useEffect(() => {
        const r = selectedCountryUsers
            ?.map((i) => {
                return i.email;
            })
            .join(";");

        if (r) setRecipients(r!);
    }, [selectedCountryUsers]);

    useEffect(() => {

        const listItems = props.selectedAthletes.map(a => "<li><b>" + a.name + "</b>  / " + a.accreditationNumber + " / " + a.sport + "</li>").join(" ");
        const sampleMarkup = "<ol>" + listItems + "</ol>";
      
      const blocksFromHTML = convertFromHTML(sampleMarkup);
      
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );

      const content = JSON.stringify(convertToRaw(state))

      setDefaultContent(content);
        
    }, [props.selectedAthletes]);

    const sendNotification = () => {
        sendNotificationForSelectedCountry(message, recipients!);

        setMessage("");

        props.closeNotificationDialog(false);
    };

    const handleChangeMessage = (state: any) => {
        let html = stateToHTML(state.getCurrentContent());

        setMessage(html);
    };

    return (
        <React.Fragment>
            <Modal
                open={props.isOpen}
                onClose={() => props.closeNotificationDialog(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >
                <Container>
                    <Card className={classes.root}>
                        <CardContent>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Typography>
                                        Send reminder to: {props.countryName}{" "}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="standard-basic"
                                        label="Email list"
                                        value={recipients}
                                        onChange={(e) => {
                                            setRecipients(e.target.value);
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} style={{minHeight: "200px"}}>
                                <Typography>
                                        Reminder message:
                                    </Typography>
                                    <MUIRichTextEditor
                                        defaultValue={defaultContent}
                                        controls={[
                                            "bold",
                                            "italic",
                                            "numberList",
                                            "bulletList",
                                        ]}
                                        inlineToolbar={true}
                                        inlineToolbarControls={[
                                            "bold",
                                            "italic",
                                        ]}
                                        onChange={(state: any) => {
                                            handleChangeMessage(state);
                                        }}
                                        label="Start typing..."
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Button
                                color="primary"
                                onClick={sendNotification}
                                disabled={recipients?.length! < 3}
                                size="medium"
                            >
                                Send Notification
                            </Button>
                            <Button
                                color="secondary"
                                onClick={() =>
                                    props.closeNotificationDialog(false)
                                }
                                size="medium"
                            >
                                Close
                            </Button>
                        </CardActions>
                    </Card>
                </Container>
            </Modal>
        </React.Fragment>
    );
}
import { RegistrationAdminInfo } from "../models/RegistrationAdminInfo";
import { PagingParameter } from "../models/PagingParameter";
import React, { useContext, useEffect, useState } from "react";
import { Configuration } from "../runtime";
import { AccountInfo, SilentRequest } from "@azure/msal-browser";
import { MsalContext } from "@azure/msal-react";
import { apiAuthority, apiBasePath, apiScopes } from "../config";
import { AdminApi } from "../apis";
import { Country } from "../models/Country";
import { ApplicationInfo } from "../models";
import { User } from "../models/User";

import { CreatePDF } from "../controls/AdminPage/RegistrationList/PDFCreator";

type AdminContextType = {
    loadingRegistrations: boolean;
    setLoadingRegistrations: (b: boolean) => void;
    registrations: RegistrationAdminInfo[];
    registrationsPage: PagingParameter;
    setRegistrationsPage: (p: PagingParameter) => void;
    registrationsCount: number;
    searchTerm: string;
    setSearchTerm: (s: string) => void;
    isLoading: boolean;
    downloadRegistrationsCSV: () => void;
    countries: Country[];
    selectedCountry: Country | null;
    setSelectedCountry: (c: Country) => void;
    selectedCountryData: ApplicationInfo | null;
    selectedCountryUsers: User[] | null;
    sendNotificationForSelectedCountry: (
        message: string,
        recipients: string
    ) => void;
    exportRegistrationsForAthlete: (a: RegistrationAdminInfo) => void;
    selectedRegistrations: RegistrationAdminInfo[] | null;
    setSelectedRegistrations: (r: RegistrationAdminInfo[]) => void;
    resetCache: () => void;
    listDescription: string; 
};

export const AdminContext = React.createContext<AdminContextType>({
    loadingRegistrations: false,
    setLoadingRegistrations: (b: boolean) => null,
    registrations: [],
    registrationsPage: { pageNumber: 0, pageSize: 20 },
    setRegistrationsPage: (p: PagingParameter) => null,
    registrationsCount: 0,
    searchTerm: "",
    setSearchTerm: (s: string) => null,
    isLoading: false,
    downloadRegistrationsCSV: () => null,
    countries: [],
    selectedCountry: null,
    setSelectedCountry: (c: Country) => null,
    selectedCountryData: null,
    selectedCountryUsers: null,
    sendNotificationForSelectedCountry: (message: string, recipients: string) =>
        null,
    exportRegistrationsForAthlete: (a: RegistrationAdminInfo) => null,
    selectedRegistrations: [],
    setSelectedRegistrations: (r: RegistrationAdminInfo[]) => null,
    resetCache: () => null,
    listDescription: ""
});

AdminContext.displayName = "AdminContext";

export function useAdminContext() {
    const msalContext = useContext(MsalContext);

    const [loadingRegistrations, setLoadingRegistrations] = useState(false);
    const [registrationsPage, setRegistrationsPage] = useState<PagingParameter>(
        {
            pageNumber: 0,
            pageSize: 20,
        }
    );

    const [selectedCountryData, setSelectedCountryData] =
        useState<ApplicationInfo | null>(null);

    const [selectedRegistrations, setSelectedRegistrations] = useState<
        RegistrationAdminInfo[]
    >([]);

    const [selectedCountryUsers, setSelectedCountryUsers] = useState<User[]>(
        []
    );

    const [selectedCountry, setSelectedCountry] = useState<Country | null>(
        null
    );

    const [isSearch, setIsSearch] = useState(false);

    const [searchTerm, setSearchTerm] = useState("");

    const [registrationsCount, setRegistrationsCount] = useState(0);
    const [registrations, setRegistrations] = useState<RegistrationAdminInfo[]>(
        []
    );

    // eslint-disable-next-line
    const [countries, setCountries] = <any>useState();

    const [isLoading, setIsLoading] = useState(false);

    const [listDescription, setListDescription] = useState("");

    const silentRequest = () => {
        if (msalContext.accounts[0]) {
            const accessTokenRequest: SilentRequest = {
                scopes: apiScopes,
                account: msalContext.accounts[0] as AccountInfo,
                authority: apiAuthority,
            } as SilentRequest;

            return msalContext.instance
                .acquireTokenSilent(accessTokenRequest)
                .then((response) => {
                    const accessToken = response.accessToken;
                    let c: Configuration = new Configuration({
                        accessToken: accessToken,
                        basePath: apiBasePath,
                    });
                    return c;
                });
        }
        return Promise.reject();
    };

    const downloadRegistrationsCSV = () => {
        return silentRequest().then((c) => {
            let at = c.accessToken!("mumu", []);

            fetch("/api/admin/download", {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + at,
                },
            })
                .then((response) => response.blob())
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `Export.csv`);

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode!.removeChild(link);
                });
        });
    };

    useEffect(() => {
        getCountriesFromAPI();

        getRegistrationsFromAPI({ pageNumber: 0, pageSize: 20 });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (searchTerm.length > 3) {
            setIsSearch(true);

            const delayDebounceFn = setTimeout(() => {
                console.log(searchTerm);

                getSearchResultsFromAPI(
                    { pageNumber: 0, pageSize: 20 },
                    searchTerm
                );
            }, 500);

            return () => clearTimeout(delayDebounceFn);
        } else {
            if (isSearch) {
                getRegistrationsFromAPI({ pageNumber: 0, pageSize: 20 });
                setIsSearch(false);
            }
        }
        // eslint-disable-next-line
    }, [searchTerm]);

    useEffect(() => {
        if (searchTerm.length > 3) {
            getSearchResultsFromAPI(
                { pageNumber: registrationsPage.pageNumber, pageSize: 20 },
                searchTerm
            );
        } else {
            getRegistrationsFromAPI({
                pageNumber: registrationsPage.pageNumber,
                pageSize: 20,
            });
        }
        // eslint-disable-next-line
    }, [registrationsPage]);

    useEffect(() => {
        getCountryStatsFromAPI();
        getCountryUsersFromAPI();
        // eslint-disable-next-line
    }, [selectedCountry]);

    const getSearchResultsFromAPI = (p: PagingParameter, s: string) => {
        setIsLoading(true);
        silentRequest().then((c) => {
            new AdminApi(c)
                .apiAdminSearchGet({
                    searchTerm: s,
                    pageSize: p.pageSize,
                    pageNumber: p.pageNumber,
                })
                .then((results) => {
                    setRegistrations(results.registrations!);
                    setRegistrationsCount(results.count?.countResult!);
                    setIsLoading(false);
                });
        });
    };

    const getCountriesFromAPI = () => {
        silentRequest().then((c) => {
            new AdminApi(c).apiAdminCountriesGet().then((results) => {
                const sorted = results.sort((a, b) => {
                    if (a.countryName! > b.countryName!) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                setCountries(sorted);
            });
        });
    };

    const getCountryStatsFromAPI = () => {
        if (selectedCountry) {
            silentRequest().then((c) => {
                new AdminApi(c)
                    .apiAdminStatsGet({ countryID: selectedCountry?.countryID })
                    .then((results) => {
                        setSelectedCountryData(results);
                    });
            });
        }
    };

    const getCountryUsersFromAPI = () => {
        if (selectedCountry) {
            silentRequest().then((c) => {
                new AdminApi(c)
                    .apiAdminUsersGet({
                        countryId: selectedCountry.countryID!.toString(),
                    })
                    .then((results) => {
                        setSelectedCountryUsers(results);
                    });
            });
        }
    };

    const sendNotificationForSelectedCountry = (
        message: string,
        recipients: string
    ) => {
        return silentRequest().then((c) => {
            new AdminApi(c).apiAdminNotificationPost({
                countryId: selectedCountry?.countryID?.toString(),
                message: message,
                usersList: recipients,
            });
        });
    };

    const resetCache = () => {
        return silentRequest().then((c) => {
            new AdminApi(c).apiAdminResetcacheGet();
        });
    };

    const getRegistrationsFromAPI = (p: PagingParameter) => {
        setIsLoading(true);

        silentRequest().then((c) => {
            new AdminApi(c)
                .apiAdminRegistrationsGet({
                    pageNumber: p.pageNumber,
                    pageSize: p.pageSize,
                })
                .then((result) => {
                    setRegistrations(result.registrations!);
                    setRegistrationsCount(result.count?.countResult!);
                    setListDescription(result.count?.countType!);
                    setIsLoading(false);
                });
        });
    };

    const exportRegistrationsForAthlete = (a: RegistrationAdminInfo) => {
        silentRequest().then((c) => {
            new AdminApi(c)
                .apiAdminRegistrationsIdGet({ id: parseInt(a.athleteId!) })
                .then((result) => {
                    CreatePDF(a, result.length);
                });
        });
    };

    return {
        loadingRegistrations,
        setLoadingRegistrations,
        registrations,
        registrationsPage,
        setRegistrationsPage,
        registrationsCount,
        searchTerm,
        setSearchTerm,
        isLoading,
        downloadRegistrationsCSV,
        countries,
        selectedCountry,
        setSelectedCountry,
        selectedCountryData,
        selectedCountryUsers,
        sendNotificationForSelectedCountry,
        exportRegistrationsForAthlete,
        selectedRegistrations,
        setSelectedRegistrations,
        resetCache, 
        listDescription
    };
}

export {};

/* tslint:disable */
/* eslint-disable */
/**
 * ITA_Rooming_Scaffold
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Athlete,
    AthleteFromJSON,
    AthleteToJSON,
    CountResult,
    CountResultFromJSON,
    CountResultToJSON,
} from '../models';

export interface ApiAthletesCountGetRequest {
    filter?: string;
}

export interface ApiAthletesGetRequest {
    pageNumber?: number;
    pageSize?: number;
    pageFilter?: string;
}

export interface ApiAthletesIdGetRequest {
    id: number;
}

/**
 * 
 */
export class AthletesApi extends runtime.BaseAPI {

    /**
     */
    async apiAthletesCountGetRaw(requestParameters: ApiAthletesCountGetRequest): Promise<runtime.ApiResponse<CountResult>> {
        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Athletes/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CountResultFromJSON(jsonValue));
    }

    /**
     */
    async apiAthletesCountGet(requestParameters: ApiAthletesCountGetRequest): Promise<CountResult> {
        const response = await this.apiAthletesCountGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAthletesDownloadGetRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Athletes/download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAthletesDownloadGet(): Promise<void> {
        await this.apiAthletesDownloadGetRaw();
    }

    /**
     */
    async apiAthletesGetRaw(requestParameters: ApiAthletesGetRequest): Promise<runtime.ApiResponse<Array<Athlete>>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageFilter !== undefined) {
            queryParameters['pageFilter'] = requestParameters.pageFilter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Athletes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AthleteFromJSON));
    }

    /**
     */
    async apiAthletesGet(requestParameters: ApiAthletesGetRequest): Promise<Array<Athlete>> {
        const response = await this.apiAthletesGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAthletesIdGetRaw(requestParameters: ApiAthletesIdGetRequest): Promise<runtime.ApiResponse<Athlete>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAthletesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Athletes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AthleteFromJSON(jsonValue));
    }

    /**
     */
    async apiAthletesIdGet(requestParameters: ApiAthletesIdGetRequest): Promise<Athlete> {
        const response = await this.apiAthletesIdGetRaw(requestParameters);
        return await response.value();
    }

}
